/**
 * axios setup to use mock service
 */

import axios from 'axios';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';

const axiosServices = axios.create({
    baseURL: process.env.REACT_APP_SERVICE_URL,
    headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json'
    }
});

// refresh token
axiosServices.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('serviceToken');
        try {
            if (token) {
                const { exp }: any = jwt_decode(token);
                if (dayjs(exp * 1000 - 1000 * 60 * 30).diff(dayjs()) < 1) {
                    const res = await axios.post(`${process.env.REACT_APP_SERVICE_URL}accounts/api-token-refresh/`, { token });
                    if (res.status === 200) {
                        //  setting updated token
                        localStorage.setItem('serviceToken', res.data.data.token);
                    }
                }
            }
        } catch (e) {
            localStorage.removeItem('serviceToken');
        }
        return config;
    },
    (err) => {
        console.log('error in getting ', err);
    }
);

// interceptor for http
axiosServices.interceptors.request.use((request) => {
    const token = localStorage.getItem('serviceToken');
    if (token) {
        request.headers!.Authorization = `JWT ${token}`;
    }

    return request;
});

// check user login
axiosServices.interceptors.response.use(
    (res) => res,
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== '/accounts/api-token-auth/' && err.response) {
            // Access Token was expired
            if (err.response.status === 401) {
                const token = localStorage.getItem('serviceToken');
                if (token) {
                    localStorage.removeItem('serviceToken');
                    window.location.reload();
                }
                return axiosServices(originalConfig);
            }
        }
        return Promise.reject(err);
    }
);

export default axiosServices;
