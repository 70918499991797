import { Box, CircularProgress } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosServices from 'utils/axiosServices';
import CustomizedTables from './CustomizedTables';

// type
type AttributesT = {
    id: number;
    name: string;
    description: string;
    created_at: string;
    updated_at: string;
};

export type UsersRolesResT = {
    data: {
        type: string;
        id: number;
        attributes: {
            authority: AttributesT;
            map: {
                id: number;
                module: AttributesT;
                point: AttributesT;
            };
            access: boolean;
            updated_at: string;
        };
    }[];
};

type AccessPointesResT = {
    data: {
        type: string;
        id: number;
        attributes: AttributesT;
    }[];
};
type SystemModuleResT = {
    data: {
        type: string;
        id: number;
        attributes: AttributesT;
    }[];
};

// state type
export type UsersRolesT = {
    id: number;
    access: boolean;
    authority: string;
    module: {
        id: number;
        name: string;
    };
    point: {
        id: number;
        name: string;
    };
};

export type AccessiblePointsT = {
    id: number;
    name: string;
    checked: boolean;
};
export type SystemModulesT = {
    id: number;
    name: string;
    checked: boolean;
};

export default function AclEdit() {
    const [usersRoles, setUsersRoles] = useState<UsersRolesT[]>([]);
    const [accessiblePoints, setAccessiblePoints] = useState<AccessiblePointsT[]>([]);
    const [systemModules, setSystemModules] = useState<SystemModulesT[]>([]);

    const { authorityId } = useParams();
    useEffect(() => {
        const getUserAccess = async () => {
            const usersRolesRes: AxiosResponse<UsersRolesResT> = await axiosServices.post(
                `/users/permissions/system-acl/authorities/${authorityId}/`
            );
            const usersRolesMapData = usersRolesRes.data.data.map((usersRole) => ({
                id: usersRole.id,
                access: usersRole.attributes.access,
                authority: usersRole.attributes.authority.name,
                module: { id: usersRole.attributes.map.module.id, name: usersRole.attributes.map.module.name },
                point: {
                    id: usersRole.attributes.map.point.id,
                    name: usersRole.attributes.map.point.name
                }
            }));
            setUsersRoles(usersRolesMapData);
        };

        const getAccessiblePoints = async () => {
            const accessiblePointsRes: AxiosResponse<AccessPointesResT> = await axiosServices.post(`/users/permissions/accessible-points/`);

            const accessiblePointsMapData = accessiblePointsRes.data.data.map((accessiblePoint) => ({
                id: accessiblePoint.id,
                name: accessiblePoint.attributes.name,
                checked: false
            }));
            setAccessiblePoints(accessiblePointsMapData);
        };
        const getSystemModule = async () => {
            const systemModulesRes: AxiosResponse<SystemModuleResT> = await axiosServices.post(`/users/permissions/system-modules/`);
            const systemModulesMapData = systemModulesRes.data.data.map((accessiblePoint) => ({
                id: accessiblePoint.id,
                name: accessiblePoint.attributes.name,
                checked: false
            }));
            setSystemModules(systemModulesMapData);
        };
        getUserAccess();
        getAccessiblePoints();
        getSystemModule();
    }, [authorityId]);

    return (
        <>
            {/* <Typography variant="body1" color="initial">
                {authorityId}
            </Typography> */}
            {usersRoles.length > 0 && accessiblePoints.length > 0 && systemModules.length > 0 ? (
                <CustomizedTables
                    authorityId={authorityId!}
                    usersRoles={usersRoles}
                    accessiblePoints={accessiblePoints}
                    systemModules={systemModules}
                    setUsersRoles={setUsersRoles}
                    setSystemModules={setSystemModules}
                    setAccessiblePoints={setAccessiblePoints}
                />
            ) : (
                <Box textAlign="center">
                    <CircularProgress />
                </Box>
            )}
        </>
    );
}
