import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import useAuth from 'hooks/useAuth';
import { checkForumMultipleAccess } from 'utils/Helpers';
import AccessDenied from 'components/common/AccessDenied';
import DataLoading from 'components/common/DataLoading';
import { AccessType } from 'types/UserDataTypes';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';

export type TopicDataType = { topic: string; is_editable: boolean; is_deletable: boolean };

export default function EditTopic() {
    const [loading, setLoading] = useState(true);
    const [access, setAccess] = useState<AccessType>({ read: false });
    const [title, setTitle] = useState('');
    const [topicData, setTopicData] = useState<TopicDataType>({
        topic: '',
        is_editable: true,
        is_deletable: false
    });
    const navigate = useNavigate();
    const { forumID, topicID } = useParams();
    const { user } = useAuth();
    const dispatch = useDispatch();

    const onSubmitHandle = async (values: any) => {
        if (forumID && topicID) {
            const postData: any = {
                data: {
                    type: 'Topic',
                    id: topicID,
                    attributes: {
                        name: values.topic,
                        is_editable: values.is_editable,
                        is_deletable: values.is_deletable
                    }
                }
            };

            setLoading(true);
            axiosServices.patch(`forum-management/forums/${forumID}/topics/${topicID}/update/`, postData).then((res) => {
                if (res.status === 200) {
                    navigate(`/forum-topics/${forumID}`, { replace: true });
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: 'Topic Updated',
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                }
                setLoading(false);
            });
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: topicData,
        validationSchema: yup.object({
            topic: yup.string().min(3, 'Too Short!').required('Topic Title is required')
        }),
        onSubmit: onSubmitHandle
    });

    useEffect(() => {
        const init = async () => {
            if (user && user.acl && forumID && topicID) {
                setLoading(true);
                const haveAccess = await checkForumMultipleAccess(parseInt(forumID, 10), 'topic', ['read', 'create', 'update', 'delete']);
                if (haveAccess && haveAccess.create) {
                    setAccess(haveAccess);
                    axiosServices.post(`forum-management/forums/${forumID}/topics/${topicID}/details/`).then((res) => {
                        if (res.status === 200 && res.data.data) {
                            setTitle(res.data.data.attributes?.forum?.name);
                            setTopicData({
                                topic: res.data.data.attributes?.name,
                                is_editable: res.data.data.attributes?.is_editable,
                                is_deletable: res.data.data.attributes?.is_deletable
                            });
                            setLoading(false);
                        }
                    });
                } else {
                    navigate('/forum-list', { replace: true });
                }
            }
        };
        init();
    }, [forumID, topicID, user, navigate]);

    return (
        <>
            {loading && <DataLoading />}
            {!loading && !access.create && <AccessDenied />}
            {!loading && access.create && (
                <Container maxWidth="sm" sx={{ background: '#FFF', borderRadius: 5, padding: 4 }}>
                    <Typography variant="h2" color="initial" marginY={3}>
                        {title}
                    </Typography>
                    <Typography variant="h2" color="initial" textAlign="center" marginY={3}>
                        {`${langString('edit')} ${langString('topic')}`}
                    </Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            fullWidth
                            sx={{ marginBottom: 10 }}
                            size="small"
                            id="topic"
                            name="topic"
                            type="text"
                            placeholder={`${langString('topic')} ${langString('title')}:`}
                            label={
                                <>
                                    {`${langString('topic')} ${langString('title')}:`}
                                    <Typography component="span" color="red">
                                        *
                                    </Typography>
                                </>
                            }
                            value={formik.values.topic}
                            onChange={(event) => formik.setFieldValue('topic', event.target.value)}
                            error={formik.touched.topic && Boolean(formik.errors.topic)}
                            helperText={formik.touched.topic && formik.errors.topic}
                        />
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.is_editable}
                                        onChange={(event) => formik.setFieldValue('is_editable', event.target.checked)}
                                    />
                                }
                                label="Editable"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.is_deletable}
                                        onChange={(event) => formik.setFieldValue('is_deletable', event.target.checked)}
                                    />
                                }
                                label="Deletable"
                            />
                        </FormGroup>
                        <Box textAlign="center" marginY={1}>
                            <Button
                                variant="contained"
                                type="reset"
                                onClick={() => {
                                    formik.resetForm();
                                    navigate(`/forum-topics/${forumID}`);
                                }}
                                color="inherit"
                                sx={{ marginRight: 1 }}
                            >
                                {langString('cancel')}
                            </Button>
                            <Button variant="contained" type="submit">
                                {langString('save')}
                            </Button>
                        </Box>
                    </form>
                </Container>
            )}
        </>
    );
}
