import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import DataLoading from 'components/common/DataLoading';

type TopicType = {
    id: number;
    topicTitle: string;
    views: number;
    discussions: number;
    action: string;
    isDelete?: boolean;
    isEdit?: boolean;
};
export default function GuestTopics() {
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('');
    const [rows, setRows] = useState<TopicType[]>([]);
    const { forumID } = useParams();

    // server request
    useEffect(() => {
        const init = async () => {
            if (forumID) {
                axiosServices.post(`forum-management/forums/${forumID}/topics/`).then((res) => {
                    if (res.status === 200 && res.data.data) {
                        const topicList: TopicType[] = [];
                        res.data.data.forEach((topic: any) =>
                            topicList.push({
                                id: topic.id,
                                topicTitle: topic.attributes.name,
                                views: topic.attributes.views,
                                discussions: topic.attributes.primary_discussions,
                                action: `/guest-forum-content/${forumID}/${topic.id}`,
                                isDelete: topic.attributes.is_deletable,
                                isEdit: topic.attributes.is_editable
                            })
                        );
                        setRows(topicList);
                        setLoading(false);
                    }
                });
            }
            setLoading(false);
        };
        init();
    }, [forumID]);

    useEffect(() => {
        const init = async () => {
            if (forumID) {
                axiosServices.post(`forum-management/forums/${forumID}/details/`).then((res) => {
                    if (res.status === 200 && res.data.data) {
                        setTitle(res.data.data.attributes.name);
                    }
                });
            }
        };
        init();
    }, [forumID]);

    return (
        <>
            {loading && <DataLoading />}
            {!loading && (
                <Box sx={{ display: { xs: 'none', md: 'block' }, marginLeft: 20, marginRight: 20, padding: 5 }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={8} sm={12}>
                            <Typography variant="h2" color="initial" textAlign="center" marginY="20px">
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} spacing={3} justifyItems="center" alignItems="center" marginTop="20px">
                        <Grid item sm={12} xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {langString('topic')} {langString('title')}
                                            </TableCell>
                                            <TableCell align="center">{langString('views')}</TableCell>
                                            <TableCell align="center">{langString('discussion')}</TableCell>
                                            <TableCell align="center">{langString('action')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.length ? (
                                            rows.map((row) => (
                                                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell component="th" scope="row">
                                                        {row.topicTitle}
                                                    </TableCell>
                                                    <TableCell align="center">{row.views}</TableCell>
                                                    <TableCell align="center">{row.discussions}</TableCell>
                                                    <TableCell align="center">
                                                        <Button
                                                            size="small"
                                                            component={Link}
                                                            to={row.action}
                                                            variant="contained"
                                                            sx={{ margin: 1 }}
                                                        >
                                                            {langString('view')}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell align="center" colSpan={4}>
                                                    <Typography variant="h4" color="initial" sx={{ padding: 5, textAlign: 'center' }}>
                                                        {langString('datanotavailable')}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
}
