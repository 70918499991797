import { Box, Button, CircularProgress, Container, Modal, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosServices from 'utils/axiosServices';
import { dateTimeDuration, isPrevDate } from 'utils/date';
import { checkForumMultipleAccess } from 'utils/Helpers';
import langString from 'utils/langString';
import { EndSessionModal } from './EndSessionModal';
import { MeetingRecordModal } from './MeetingRecordModal';
import { QuestionBank } from './QuestionBank';
import { rowsDataTable } from './RowsDataTable';
import { LiveSessionDetailsT, LiveSessionT, MeetingsDetailsT, QuestionBankResT } from './type';

const LiveSession = () => {
    const [open, setOpen] = useState(false);
    const [openSessionEnd, setOpenSessionEnd] = useState(false);
    const [openMeetingRecord, setOpenMeetingRecord] = useState(false);
    const [rows, setRows] = useState<rowsDataTable[]>([]);
    const [liveSession, setLiveSession] = useState<LiveSessionT>();
    const { forumID, liveSessionId } = useParams();
    // modal open and close
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenSessionEnd = () => {
        setOpenSessionEnd(true);
    };

    const handleCloseSessionEnd = () => {
        setOpenSessionEnd(false);
    };
    const handleOpenMeetingRecord = () => {
        setOpenMeetingRecord(true);
    };

    const handleCloseMeetingRecord = () => {
        setOpenMeetingRecord(false);
    };

    // server request
    useEffect(() => {
        const getDetails = async () => {
            if (forumID && liveSessionId) {
                const access = await checkForumMultipleAccess(parseInt(forumID, 10), 'live-session', ['create', 'read', 'delete']);

                const liveSessionDetails: AxiosResponse<LiveSessionDetailsT> = await axiosServices.post(
                    `forum-management/forums/${forumID}/sessions/${liveSessionId}/details/`
                );
                const meetingsDetails: AxiosResponse<MeetingsDetailsT> = await axiosServices.post(
                    `forum-management/forums/${forumID}/sessions/${liveSessionId}/meetings/details/`
                );
                const questionsRes: AxiosResponse<QuestionBankResT> = await axiosServices.post(
                    `forum-management/forums/${forumID}/sessions/${liveSessionId}/questions/`
                );

                const questionData = questionsRes.data.data.map((val) => ({
                    question: val.attributes.question.id,
                    comment: val.attributes.question.comment,
                    answered: val.attributes.answered
                }));
                setRows(questionData);

                const data = {
                    ...access,
                    ...meetingsDetails.data.data.attributes,
                    ...liveSessionDetails.data.data.attributes
                };
                setLiveSession(data);
            }
        };
        getDetails();
    }, [liveSessionId, forumID]);

    const handleClick = async (url: string, endSession: boolean = false) => {
        const createRes: AxiosResponse<any> = await axiosServices.post(url);
        if (!endSession) {
            window.open(createRes.data.data.join_url, '_blank');
        }

        window.location.reload();
    };

    if (!liveSession) {
        return (
            <Box textAlign="center">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Container maxWidth="sm">
                <Typography variant="h2" color="initial" textAlign="center" marginY="20px">
                    {liveSession.forum.name}
                </Typography>
                <Typography variant="h3" color="initial" textAlign="center" marginY="20px">
                    {`${langString('time')} ${langString('left')}:`}{' '}
                    {liveSession.has_started ? '00' : dateTimeDuration(liveSession.start_datetime)}
                </Typography>
                <Box textAlign="center" marginY={2}>
                    {liveSession.create &&
                    liveSession.running === false &&
                    liveSession.has_started === false &&
                    liveSession.completed === false ? (
                        <Box>
                            <Button
                                disabled={isPrevDate(liveSession.start_datetime)}
                                onClick={() => handleClick(`forum-management/forums/${forumID}/sessions/${liveSessionId}/meetings/create/`)}
                                variant="contained"
                                sx={{ paddingY: '13px', borderRadius: '0px', marginBottom: 2 }}
                            >
                                {langString('createandjoinmeeting')}
                            </Button>
                        </Box>
                    ) : (
                        ''
                    )}
                    {liveSession.read && liveSession.completed === false ? (
                        <Box>
                            <Button
                                disabled={liveSession.has_started === false && liveSession.running === false}
                                onClick={() => handleClick(`forum-management/forums/${forumID}/sessions/${liveSessionId}/meetings/join/`)}
                                variant="contained"
                                sx={{ paddingY: '13px', borderRadius: '0px', marginBottom: 2 }}
                            >
                                {`${langString('join')} ${langString('session')}`}
                            </Button>
                        </Box>
                    ) : (
                        ''
                    )}
                    {liveSession.delete && liveSession.running && liveSession.has_started && liveSession.completed === false ? (
                        <Box>
                            <Button
                                onClick={handleOpenSessionEnd}
                                variant="contained"
                                sx={{ paddingY: '13px', borderRadius: '0px', marginBottom: 2 }}
                            >
                                {langString('endmeeting')}
                            </Button>
                            <Modal
                                disableEscapeKeyDown
                                open={openSessionEnd}
                                onClose={handleCloseSessionEnd}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <EndSessionModal
                                    handleClose={handleCloseSessionEnd}
                                    link={`forum-management/forums/${forumID}/sessions/${liveSessionId}/meetings/end/`}
                                    handleClick={handleClick}
                                />
                            </Modal>
                        </Box>
                    ) : (
                        ''
                    )}
                    {liveSession.read && liveSession.completed ? (
                        <Box>
                            <Button
                                onClick={handleOpenMeetingRecord}
                                variant="contained"
                                sx={{ paddingY: '13px', borderRadius: '0px', marginBottom: 2 }}
                            >
                                {langString('showrecordings')}
                            </Button>
                            <Modal
                                disableEscapeKeyDown
                                open={openMeetingRecord}
                                onClose={handleCloseSessionEnd}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <MeetingRecordModal handleClose={handleCloseMeetingRecord} meetingRecordList={liveSession.meeting_record} />
                            </Modal>
                        </Box>
                    ) : (
                        ''
                    )}
                </Box>
                <Box textAlign="center" marginY={2}>
                    <Button onClick={handleOpen} variant="contained" sx={{ paddingY: '13px', borderRadius: '0px', marginBottom: 2 }}>
                        {`${langString('question')} ${langString('bank')}`}
                    </Button>
                    <Modal
                        disableEscapeKeyDown
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <QuestionBank handleClose={handleClose} rows={rows} setRows={setRows} />
                    </Modal>
                </Box>
            </Container>
        </>
    );
};

export default LiveSession;
