import { Box, Button, Container, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import useAuth from 'hooks/useAuth';
import { checkForumMultipleAccess } from 'utils/Helpers';
import AccessDenied from 'components/common/AccessDenied';
import DataLoading from 'components/common/DataLoading';
import { AccessType } from 'types/UserDataTypes';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';

export type FaqDataType = { question: string; answer: string };

export default function EditFaq() {
    const [loading, setLoading] = useState(true);
    const [access, setAccess] = useState<AccessType>({ read: false });
    const [title, setTitle] = useState('');
    const [faqData, setFaqData] = useState<FaqDataType>({
        question: '',
        answer: ''
    });
    const navigate = useNavigate();
    const { forumID, faqID } = useParams();
    const { user } = useAuth();
    const dispatch = useDispatch();

    const onSubmitHandle = async (values: any) => {
        if (forumID && faqID) {
            const postData: any = {
                data: {
                    type: 'FAQ',
                    id: faqID,
                    attributes: {
                        question: values.question,
                        answer: values.answer
                    }
                }
            };

            setLoading(true);
            axiosServices.patch(`forum-management/forums/${forumID}/faqs/${faqID}/update/`, postData).then((res) => {
                if (res.status === 200) {
                    navigate(`/manage-faq/${forumID}`, { replace: true });
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: 'FAQ Updated',
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                }
                setLoading(false);
            });
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: faqData,
        validationSchema: yup.object({
            question: yup.string().min(3, 'Too Short!').required('FAQ Question is required'),
            answer: yup.string().min(3, 'Too Short!').required('FAQ Answer is required')
        }),
        onSubmit: onSubmitHandle
    });

    useEffect(() => {
        const init = async () => {
            if (user && user.acl && forumID && faqID) {
                setLoading(true);
                const haveAccess = await checkForumMultipleAccess(parseInt(forumID, 10), 'faq', ['update']);
                if (haveAccess && haveAccess.update) {
                    setAccess(haveAccess);
                    axiosServices.post(`forum-management/forums/${forumID}/faqs/${faqID}/details/`).then((res) => {
                        if (res.status === 200 && res.data.data) {
                            setTitle(res.data.data.attributes?.forum?.name);
                            setFaqData({
                                question: res.data.data.attributes?.question,
                                answer: res.data.data.attributes?.answer
                            });
                            setLoading(false);
                        }
                    });
                } else {
                    navigate('/manage-faq', { replace: true });
                }
            }
        };
        init();
    }, [forumID, faqID, user, navigate]);

    return (
        <>
            {loading && <DataLoading />}
            {!loading && !access.update && <AccessDenied />}
            {!loading && access.update && (
                <Container maxWidth="sm" sx={{ background: '#FFF', borderRadius: 5, padding: 4 }}>
                    <Typography variant="h2" color="initial" marginY={3}>
                        {title} {langString('faq')}
                    </Typography>
                    <Typography variant="h2" color="initial" textAlign="center" marginY={3}>
                        {`${langString('edit')} ${langString('faq')}`}
                    </Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            fullWidth
                            sx={{ marginBottom: 10 }}
                            size="small"
                            id="question"
                            name="question"
                            type="text"
                            placeholder={`${langString('question')}:`}
                            label={
                                <>
                                    {`${langString('question')}:`}
                                    <Typography component="span" color="red">
                                        *
                                    </Typography>
                                </>
                            }
                            value={formik.values.question}
                            onChange={(event) => formik.setFieldValue('question', event.target.value)}
                            error={formik.touched.question && Boolean(formik.errors.question)}
                            helperText={formik.touched.question && formik.errors.question}
                        />
                        <TextField
                            fullWidth
                            sx={{ marginBottom: 10 }}
                            size="small"
                            id="answer"
                            name="answer"
                            type="text"
                            placeholder={`${langString('answer')}:`}
                            label={
                                <>
                                    {`${langString('answer')}:`}
                                    <Typography component="span" color="red">
                                        *
                                    </Typography>
                                </>
                            }
                            value={formik.values.answer}
                            onChange={(event) => formik.setFieldValue('answer', event.target.value)}
                            error={formik.touched.answer && Boolean(formik.errors.answer)}
                            helperText={formik.touched.answer && formik.errors.answer}
                        />

                        <Box textAlign="center" marginY={1}>
                            <Button
                                variant="contained"
                                type="reset"
                                onClick={() => {
                                    formik.resetForm();
                                    navigate(`/forum-topics/${forumID}`);
                                }}
                                color="inherit"
                                sx={{ marginRight: 1 }}
                            >
                                {langString('cancel')}
                            </Button>
                            <Button variant="contained" type="submit">
                                {langString('save')}
                            </Button>
                        </Box>
                    </form>
                </Container>
            )}
        </>
    );
}
