import { Box, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { checkNullInfo } from 'utils/Helpers';
import langString from 'utils/langString';
import Action from './Action';
import Counter from './Counter';
import Delete from './Delete';
import Meta from './Meta';
import Replies from './Replies';

export type CommentType = {
    type: string;
    id: number;
    attributes: {
        comment: string;
        created_by: {
            id: number;
            username: string;
            user_authority: string;
            display_name: string;
        };
        updated_by: {
            id: 2;
            username: string;
            user_authority: string;
            display_name: string;
        };
        created_at: string;
        updated_at: string;
        deleted_at: null | string;
        total_likes: number;
        liked: boolean;
        total_replies: number;
    };
};

type CommentPropsType = {
    primary: boolean;
    com: CommentType;
    forumID: number;
    refreshDiscussion: () => void;
};
export default function Comment({ com, primary, forumID, refreshDiscussion }: CommentPropsType) {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Box sx={{ marginY: 2 }}>
                <Typography variant="h5" color="#7f8c8d" sx={{ marginY: '5px' }}>
                    {langString('primary')} {langString('comment')}
                </Typography>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={10} border="1px solid #7f8c8d">
                        <Meta
                            author={com.attributes?.created_by?.display_name}
                            role={com.attributes?.created_by?.user_authority}
                            createdAt={com.attributes?.created_at}
                        />
                        <Typography variant="body1" color="#616161" marginX={1} marginY="5px" fontSize={16}>
                            {checkNullInfo(com.attributes?.comment)}
                        </Typography>
                        <Action
                            open={open}
                            setOpen={(value: boolean) => setOpen(value)}
                            primary={primary}
                            refreshDiscussion={refreshDiscussion}
                            liked={com.attributes?.liked}
                            forumID={forumID}
                            comID={com.id}
                            totalReplies={com.attributes?.total_replies}
                        />
                    </Grid>
                    <Counter primary={primary} likes={com.attributes?.total_likes} replies={com.attributes?.total_replies} />
                    <Delete
                        primary
                        refreshDiscussion={refreshDiscussion}
                        forumID={forumID}
                        comID={com.id}
                        authorID={com.attributes.created_by.id}
                    />
                </Grid>
                {open && <Replies forumID={forumID} comID={com.id} authorID={com.attributes.created_by.id} />}
            </Box>
        </>
    );
}
