import { FormControl, InputLabel, Container, Grid, Typography, Select, Button, MenuItem, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosServices from 'utils/axiosServices';
import { checkForumAccess, checkNullInfo } from 'utils/Helpers';
import langString from 'utils/langString';
import AccessDenied from 'components/common/AccessDenied';
import DataLoading from 'components/common/DataLoading';
import QuestionBankComments from './Comments';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';

export default function QuestionBankCreate() {
    const [loading, setLoading] = useState(true);
    const [bankAccess, setBankAccess] = useState(false);
    const [bankSupport, setBankSupport] = useState(false);
    const [forumName, setForumName] = useState('');
    const [topicTitle, setTopicTitle] = useState('');
    const { forumID, topicID } = useParams();
    const [liveSession, setLiveSession] = useState(0);
    const [sessions, setSessoins] = useState<any[]>([]);
    const [questions, setQuestions] = useState<number[]>([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const updateQuestions = (id: number) => {
        const selectedQuestions = [...questions];
        if (selectedQuestions.includes(id)) {
            const filteredQuestions = selectedQuestions.filter((item: number) => item !== id);
            setQuestions(filteredQuestions);
        } else {
            selectedQuestions.push(id);
            setQuestions(selectedQuestions);
        }
    };

    const saveOnQuestionBank = () => {
        if (liveSession && forumID) {
            if (questions.length) {
                const objects: any[] = [];
                questions.forEach((item: number) =>
                    objects.push({
                        question: item,
                        answered: false
                    })
                );
                const postData = {
                    data: {
                        type: 'QuestionBank',
                        attributes: {
                            objects
                        }
                    }
                };
                setLoading(true);
                axiosServices
                    .post(`forum-management/forums/${forumID}/sessions/${liveSession}/questions/update/`, postData)
                    .then((res) => {
                        if (res.status === 200 && res.data.data) {
                            dispatch({
                                type: SNACKBAR_OPEN,
                                open: true,
                                message: 'Question Bank Updated',
                                variant: 'alert',
                                alertSeverity: 'success'
                            });
                            navigate(`/content-and-discussion/${forumID}/${topicID}`, { replace: true });
                        }
                        setLoading(false);
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            } else {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'Please Select Comment/Question',
                    variant: 'alert',
                    alertSeverity: 'error'
                });
            }
        } else {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'Please Select Live Session',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    };

    useEffect(() => {
        const init = async () => {
            if (forumID && topicID) {
                const haveBankAccess = await checkForumAccess(parseInt(forumID, 10), 'question-bank', ['create']);
                if (haveBankAccess) {
                    setBankAccess(true);
                }
                axiosServices
                    .post(`forum-management/forums/${forumID}/topics/${topicID}/details/`)
                    .then((res) => {
                        if (res.status === 200 && res.data.data) {
                            setTopicTitle(res.data.data.attributes?.name);
                            setForumName(res.data.data.attributes?.forum?.name);
                            setBankSupport(res.data.data.attributes?.supports_question_bank);
                        }
                        setLoading(false);
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            }
        };
        init();
    }, [forumID, topicID]);

    useEffect(() => {
        const init = async () => {
            if (forumID) {
                axiosServices
                    .post(`forum-management/forums/${forumID}/sessions/`)
                    .then((res) => {
                        if (res.status === 200 && res.data.data) {
                            setSessoins(res.data.data);
                        }
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            }
        };
        init();
    }, [forumID]);

    return (
        <>
            <Container sx={{ background: '#FFF', borderRadius: 5, padding: 4 }}>
                {loading && <DataLoading />}
                {!loading && !bankAccess && <AccessDenied />}
                {!loading && bankAccess && bankSupport && (
                    <>
                        <Grid container>
                            <Grid item sm={12}>
                                <Typography variant="h3" color="initial" textAlign="center" marginY={5}>
                                    {checkNullInfo(forumName)} {checkNullInfo(topicTitle)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ marginTop: 5 }}>
                            <Grid item sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="live-session-select-label">{langString('selectlivesession')}</InputLabel>
                                    <Select
                                        labelId="live-session-select-label"
                                        id="live-session-select"
                                        value={liveSession}
                                        label={langString('livesession')}
                                        onChange={(event: any) => setLiveSession(event.target.value)}
                                    >
                                        {sessions.length &&
                                            sessions.map((item: any) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.attributes?.company?.name} |{' '}
                                                    {new Date(item.attributes?.start_datetime).toLocaleDateString()}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        {forumID && topicID && (
                            <QuestionBankComments
                                questions={questions}
                                updateQuestions={(value: number) => updateQuestions(value)}
                                forumID={forumID}
                                topicID={topicID}
                            />
                        )}
                        <Grid container sx={{ marginTop: 5 }}>
                            <Grid item sm={12}>
                                <Box textAlign="center" marginY={1}>
                                    <Button
                                        color="inherit"
                                        variant="contained"
                                        type="reset"
                                        onClick={() => {
                                            navigate(`/content-and-discussion/${forumID}/${topicID}`, { replace: true });
                                        }}
                                        sx={{ marginRight: 1 }}
                                    >
                                        {langString('cancel')}
                                    </Button>
                                    <Button variant="contained" onClick={() => saveOnQuestionBank()} type="submit">
                                        {langString('save')}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Container>
        </>
    );
}
