import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { Checkbox, Grid, Typography } from '@mui/material';

export type rowsDataTable = {
    question: number;
    comment: string;
    answered: boolean;
};

type rowsDataTableT = {
    handleChange: (e: any, questionId: number) => void;
    question: number;
    comment: string;
    answered: boolean;
};
export default function RowsDataTable({ question, comment, answered, handleChange }: rowsDataTableT) {
    return (
        <>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <Typography variant="h4" color="initial" noWrap sx={{ backgroundColor: '#eeeeee', padding: '15px' }}>
                    {comment}
                </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} textAlign="right">
                <Checkbox
                    checked={answered}
                    onChange={(e) => handleChange(e, question)}
                    icon={<CircleOutlinedIcon sx={{ color: 'gray' }} />}
                    checkedIcon={<CircleIcon color="primary" sx={{ border: '2px solid gray', borderRadius: 50 }} />}
                />
            </Grid>
        </>
    );
}
