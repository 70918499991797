// material-ui
// import { useTheme } from '@mui/material/styles';
// import logo from 'assets/images/logo.png';
// import logoDark from 'assets/images/logo-dark.svg';

import { Typography } from '@mui/material';

// const Logo = () => {
//     const theme = useTheme();

//     return <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="CoP" width="100" />;
// };

const Logo = () => <Typography sx={{ fontWeight: 600, fontSize: 25, color: '#120E43' }}>USAID/RI</Typography>;

export default Logo;
