import { Button, Card, CardActions, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import langString from 'utils/langString';

type primaryCard = {
    imageUrl: string;
    title: string;
    description: string;
    redirect: string;
};

export default function PrimaryCard({ imageUrl, title, description, redirect }: primaryCard) {
    return (
        <Grid item xs={12} sm={4} lg={4} xl={4}>
            <Card sx={{ border: '1px solid lightgray', borderRadius: '5px', backgroundColor: '#f5f5f5' }}>
                <CardMedia component="img" height={100} image={imageUrl} title="Forum Photo" />
                <CardContent sx={{ minHeight: 150 }}>
                    <Grid container spacing={1}>
                        <Grid item marginX="auto" textAlign="center">
                            {title && (
                                <Typography variant="subtitle1">{title.length > 16 ? `${title.substring(0, 16)}...` : title}</Typography>
                            )}
                        </Grid>
                        <Grid item textAlign="center">
                            <Typography variant="subtitle2">
                                {description && description.length > 60 ? `${description.substring(0, 60)}...` : description}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid container>
                        <Grid item marginX="auto">
                            <Button component={Link} to={redirect || '/'} variant="contained">
                                {langString('enter')}
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    );
}
