import AddIcon from '@mui/icons-material/Add';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDateTimePicker from '@mui/lab/DesktopDateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Button, Container, Grid, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosServices from 'utils/axiosServices';
import langString from 'utils/langString';
import * as yup from 'yup';
import SelectField from './SelectField';

export default function AddLiveSession() {
    const [companies, setCompanies] = useState<{ value: string; name: string }[]>([]);
    const navigate = useNavigate();
    const { forumID } = useParams();
    const onSubmitHandle = async (values: any) => {
        const representatives = values.representative.map((val: string) => ({
            name: val
        }));
        const data = {
            data: {
                type: 'Session',
                attributes: {
                    start_datetime: dayjs(values.date).format().substring(0, 16),
                    company: {
                        type: 'User',
                        id: values.company
                    },
                    representatives
                }
            }
        };
        const liveSessionsRes = await axiosServices.post(`forum-management/forums/${forumID}/sessions/create/`, data);

        if (liveSessionsRes.status === 201) {
            navigate(`/forum-topics/${forumID}/live-session-list`);
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            date: '',
            company: '',
            representative: ['']
        },
        validationSchema: yup.object({
            date: yup.string().required('Date and Time is required'),
            company: yup.string().required('Company is required')
        }),
        onSubmit: onSubmitHandle
    });
    useEffect(() => {
        const getCompany = async () => {
            const companiesRes = await axiosServices.post('settings/companies/');
            const companiesData = companiesRes.data.data.map((company: any) => ({ value: company.id, name: company.attributes.name }));

            setCompanies(companiesData);
        };
        getCompany();
    }, []);
    return (
        <>
            <Container maxWidth="sm">
                <Typography variant="h2" color="initial" textAlign="center" marginY={3}>
                    {`${langString('new')} ${langString('section')} ${langString('create')} `}
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        alignContent="stretch"
                        wrap="wrap"
                    >
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h3" color="initial">
                                {`${langString('set')} ${langString('date')} ${langString('time')}:`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDateTimePicker
                                    value={formik.values.date}
                                    onChange={(newValue) => {
                                        formik.setFieldValue('date', newValue);
                                    }}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                    views={['year', 'month', 'day', 'hours', 'minutes']}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="h3" color="initial">
                                {`${langString('set')} ${langString('company')}:`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SelectField
                                name="company"
                                labelName={
                                    <>
                                        {langString('company')}
                                        <Typography component="span" color="red">
                                            *
                                        </Typography>
                                    </>
                                }
                                formik={formik}
                                menuItems={[{ value: '', name: 'select' }, ...companies!]}
                            />
                        </Grid>

                        {formik.values.representative.map((item, indx) => (
                            <>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="h3" color="initial">
                                        {`${langString('name')} ${langString('representative')}:`}
                                    </Typography>
                                </Grid>
                                <Grid key={indx} item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        size="small"
                                        id={`representative.[${indx}]`}
                                        name={item}
                                        type="text"
                                        placeholder={`${langString('name')} ${langString('representative')}:`}
                                        label={<>{`${langString('name')} ${langString('representative')}:`}</>}
                                        value={formik.values.representative[indx]}
                                        onChange={(event) => {
                                            formik.setFieldValue(`representative[${indx}]`, event.target.value);
                                        }}
                                    />
                                </Grid>
                            </>
                        ))}
                        <Grid item xs={12} sm={12} textAlign="right">
                            <Typography
                                component={Button}
                                type="button"
                                endIcon={<AddIcon />}
                                onClick={() => {
                                    formik.setFieldValue(`representative`, [...formik.values.representative, '']);
                                    console.log(formik.values.representative);
                                }}
                            >
                                {`${langString('add')} ${langString('more')}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} textAlign="center" marginTop={10}>
                            <Button
                                variant="contained"
                                type="reset"
                                onClick={(e) => {
                                    formik.resetForm();
                                    navigate(`/forum-topics/${forumID}/live-session-list`);
                                }}
                                sx={{ marginRight: 1 }}
                            >
                                {langString('cancel')}
                            </Button>
                            <Button variant="contained" type="submit">
                                {langString('save')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </>
    );
}
