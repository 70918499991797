import React, { createContext, useEffect, useReducer } from 'react';
// import config from '../config';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT, SNACKBAR_OPEN } from 'store/actions';
import accountReducer from 'store/accountReducer';
import { useDispatch } from 'react-redux';

// project imports
import Loader from 'ui-component/Loader';

import axiosServices from '../utils/axiosServices';
import { initialLoginContextProps, KeyedObject } from 'types';
import { JWTContextType, StudentAttributeType } from 'types/auth';
import { PostDataType } from 'utils/Constants';

// constant
const initialState: initialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
    } else {
        localStorage.removeItem('serviceToken');
        delete axiosServices.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const dispatchMessage = useDispatch();
    const setUserStateData = async () => {
        const userResponse = await axiosServices.post('users/profile/');
        if (userResponse.status === 200) {
            const aclResponse = await axiosServices.post('users/permissions/system-acl/');
            if (aclResponse.status === 200) {
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: {
                            id: userResponse.data?.data?.id,
                            username: userResponse.data?.data?.attributes?.username,
                            name: userResponse.data?.data?.attributes?.profile?.name,
                            image: userResponse.data?.data?.attributes?.profile?.picture,
                            avatar: userResponse.data?.data?.attributes?.profile?.picture,
                            superUser: userResponse.data?.data?.attributes?.is_superuser,
                            acl: aclResponse.data.data
                        }
                    }
                });
            }
        } else {
            dispatch({
                type: LOGOUT
            });
        }
    };

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    setUserStateData();
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    const login = async (username: string, password: string) => {
        try {
            const postData: PostDataType = { data: { type: 'User', attributes: { username, password } } };
            const response = await axiosServices.post('accounts/api-token-auth/', postData);
            if (response.status === 200 && response.data.data.token) {
                setSession(response.data.data.token);
                setUserStateData();
            } else {
                dispatchMessage({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'Invalid Credentials',
                    variant: 'alert',
                    alertSeverity: 'error'
                });
            }
        } catch (err) {
            dispatchMessage({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'Invalid user credentials',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    };

    const register = async (studentData: StudentAttributeType) => {
        const response = await axiosServices.post('users/students/create/', { data: { type: 'User', attributes: studentData } });
        if (response.status === 201) {
            const userData = response.data.data;
            let users: any[] = [
                {
                    id: userData?.id,
                    email: userData?.attributes?.profile?.email,
                    name: userData?.attributes?.profile?.name
                }
            ];

            if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
                const localUsers = window.localStorage.getItem('users');
                users = [
                    ...JSON.parse(localUsers!),
                    {
                        id: userData?.id,
                        email: userData?.attributes?.profile?.email,
                        name: userData?.attributes?.profile?.name
                    }
                ];
            }

            window.localStorage.setItem('users', JSON.stringify(users));
        } else {
            dispatchMessage({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'Email already exist',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = async (email: string) => {
        const postData: PostDataType = {
            data: {
                type: 'User',
                attributes: {
                    email
                }
            }
        };
        return axiosServices.post('accounts/forget-password/', postData);
    };

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

export default JWTContext;
