import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import langString from 'utils/langString';
import { useParams, useNavigate } from 'react-router-dom';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import axiosServices from 'utils/axiosServices';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import DataLoading from 'components/common/DataLoading';
import { validateFormFields } from 'utils/Helpers';
import MediaForm from './MediaForm';
import { BASEURL } from 'utils/Constants';

export default function EditContent() {
    const [loading, setLoading] = useState(true);
    const [contentType, setContentType] = useState(0);
    const [contentTitle, setContentTitle] = useState('');
    const [content, setContent] = useState('');
    const [conTypes, setConTypes] = useState<any[]>([]);
    const [errors, setErrors] = useState<any>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [topicID, setTopicID] = useState(0);
    const { forumID, conID } = useParams();
    const { user } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectContentType = (id: number) => {
        const value = { id: 1, name: 'Text' };
        conTypes.forEach((item: any) => {
            if (parseInt(item.value, 10) === id) {
                value.id = item.value;
                value.name = item.name;
            }
        });
        return value;
    };
    const onSubmitHandle = async (event: any) => {
        event.preventDefault();
        setIsSubmitting(true);
        const allErrors = validateFormFields(['contentType', 'contentTitle'], [contentType, contentTitle]);
        if (allErrors.valid) {
            const contentTypeObj = selectContentType(contentType);
            const postData: any = {
                data: {
                    type: 'Content',
                    id: conID,
                    attributes: {
                        title: contentTitle,
                        content_type: contentTypeObj,
                        text: contentType === 1 ? content : null,
                        video: contentType === 2 ? content : null,
                        audio: contentType === 3 ? content : null,
                        picture: contentType === 4 ? content : null,
                        url: contentType === 5 ? content : null
                    }
                }
            };
            if (forumID && conID && user) {
                setLoading(true);
                axiosServices.patch(`forum-management/forums/${forumID}/contents/${conID}/update/`, postData).then((res) => {
                    if (res.status === 200) {
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: 'Content Updated',
                            variant: 'alert',
                            alertSeverity: 'success'
                        });
                        navigate(`/manage-content/${forumID}/${topicID}`, { replace: true });
                    }
                    setLoading(false);
                });
            }
        } else {
            setErrors(allErrors);
        }
        setIsSubmitting(false);
    };

    useEffect(() => {
        const init = async () => {
            axiosServices.post(`forum-management/forums/content-types/`).then((res) => {
                if (res.status === 200 && res.data.data) {
                    const allConTypes: any[] = [];
                    res.data.data.forEach((item: any) => allConTypes.push({ value: item.id, name: item.attributes?.name }));
                    setConTypes(allConTypes);
                }
                setLoading(false);
            });
        };
        init();
    }, []);

    useEffect(() => {
        const init = async () => {
            if (forumID && conID) {
                axiosServices.post(`forum-management/forums/${forumID}/contents/${conID}/details/`).then((res) => {
                    if (res.status === 200 && res.data.data) {
                        console.log(res.data.data.attributes);
                        setTopicID(res.data.data.attributes?.topic?.id);
                        let fileData = null;
                        if (res.data.data.attributes?.content_type?.id === 1) {
                            fileData = res.data.data.attributes?.text;
                        }
                        if (res.data.data.attributes?.content_type?.id === 2) {
                            fileData = BASEURL + res.data.data.attributes?.video;
                        }
                        if (res.data.data.attributes?.content_type?.id === 3) {
                            fileData = BASEURL + res.data.data.attributes?.audio;
                        }
                        if (res.data.data.attributes?.content_type?.id === 4) {
                            fileData = BASEURL + res.data.data.attributes?.picture;
                        }
                        if (res.data.data.attributes?.content_type?.id === 5) {
                            fileData = res.data.data.attributes?.url;
                        }
                        setContentType(res.data.data.attributes?.content_type?.id);
                        setContentTitle(res.data.data.attributes?.title);
                        setContent(fileData);
                    }
                    setLoading(false);
                });
            }
        };
        init();
    }, [conID, forumID]);

    return (
        <>
            {loading && <DataLoading />}
            <Container maxWidth="sm" sx={{ background: '#FFF', padding: 3, borderRadius: 5 }}>
                <Typography variant="h2" color="initial" textAlign="center" marginY={1}>
                    {langString('updatecontent')}
                </Typography>
                <form onSubmit={(event: any) => onSubmitHandle(event)}>
                    <FormControl fullWidth sx={{ marginY: 1 }}>
                        <InputLabel>{langString('selectcontenttype')}</InputLabel>
                        <Select
                            required
                            size="small"
                            name="contentType"
                            value={contentType}
                            onChange={(event: any) => {
                                setContent('');
                                setContentType(event.target.value * 1);
                            }}
                            label={langString('contentType')}
                            error={Boolean(errors && errors.contentType?.required)}
                        >
                            {conTypes &&
                                conTypes.map((item: any) => (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </Select>
                        {Boolean(errors && errors.contentType?.required) && (
                            <FormHelperText error>{errors.contentType?.message}</FormHelperText>
                        )}
                    </FormControl>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <TextField
                            sx={{ marginY: 1 }}
                            required
                            size="small"
                            fullWidth
                            id="contentTitle"
                            name="contentTitle"
                            label={langString('contentTitle')}
                            value={contentTitle}
                            onChange={(event: any) => setContentTitle(event.target.value)}
                        />
                        {Boolean(errors && errors.contentTitle?.required) && (
                            <FormHelperText error>{errors.contentTitle?.message}</FormHelperText>
                        )}
                    </FormControl>
                    <MediaForm content={content} setContent={(value: string) => setContent(value)} contentType={contentType} />
                    <Box textAlign="center" marginY={1}>
                        <Button
                            variant="contained"
                            color="inherit"
                            type="reset"
                            onClick={() => {
                                navigate(`/manage-content/${forumID}/${topicID}`, { replace: true });
                            }}
                            sx={{ marginRight: 1 }}
                        >
                            {langString('cancel')}
                        </Button>
                        <Button disabled={isSubmitting} variant="contained" type="submit">
                            {langString('save')}
                        </Button>
                    </Box>
                </form>
            </Container>
        </>
    );
}
