import { Box, FormControl, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import axiosServices from 'utils/axiosServices';
import { PostDataType } from 'utils/Constants';
import langString from 'utils/langString';

type UserInformationPropsType = {
    username: string;
    setUsername: (value: string) => void;
    password: string;
    setPassword: (value: string) => void;
    usernameExist: boolean;
    setUsernameExist: (value: boolean) => void;
};
export default function UserInformation({
    username,
    setUsername,
    password,
    setPassword,
    usernameExist,
    setUsernameExist
}: UserInformationPropsType) {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [confirm, setConfirm] = useState('');

    const usernameCheck = async (value: string) => {
        setUsername(value);
        if (value) {
            const postData: PostDataType = { data: { type: 'User', attributes: { username: value } } };
            const response = await axiosServices.post('users/check-exists/', postData);
            if (response.status === 201) {
                setUsernameExist(false);
            } else {
                setUsernameExist(true);
            }
        }
    };

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2, mt: 3 }}>
                        <Typography variant="subtitle1">{langString('userinformation')}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={matchDownSM ? 0 : 2}>
                <Grid item xs={12} sm={6}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                <TextField
                                    fullWidth
                                    label={`${langString('username')} *`}
                                    margin="normal"
                                    name="username"
                                    type="text"
                                    value={username}
                                    onChange={(event: any) => usernameCheck(event.target.value)}
                                    sx={{ ...theme.typography.customInput }}
                                />
                                {usernameExist && <strong style={{ color: 'red' }}>Username already exists!</strong>}
                            </FormControl>
                            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                <TextField
                                    fullWidth
                                    label={`${langString('password')} *`}
                                    margin="normal"
                                    name="password"
                                    type="password"
                                    value={password}
                                    onChange={(event: any) => setPassword(event.target.value)}
                                    sx={{ ...theme.typography.customInput }}
                                />
                            </FormControl>
                            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                <TextField
                                    fullWidth
                                    label={`${langString('confirmpassword')} *`}
                                    margin="normal"
                                    name="confirmpassword"
                                    type="password"
                                    value={confirm}
                                    onChange={(event: any) => setConfirm(event.target.value)}
                                    sx={{ ...theme.typography.customInput }}
                                />
                                {password && password !== confirm && <strong style={{ color: 'red' }}>Password not matched!</strong>}
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
