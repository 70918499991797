// project imports
import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Acl from 'views/acl';
import AclEdit from 'views/acl/edit';
import ForumAcl from 'views/acl/forumAcl';
import ForumAclEdit from 'views/acl/forumAcl/edit';
import ForumsAclList from 'views/acl/forumAcl/forumAclList';
import AddLiveSession from 'views/addLiveSession';
import ContentAndDiscussion from 'views/contentAndDiscussion';
import Faq from 'views/faq';
import ManageFaq from 'views/faq/manage';
import AddFaq from 'views/faq/manage/AddFaq';
import EditFaq from 'views/faq/manage/EditFaq';
import Feedback from 'views/Feedback';
import ForumList from 'views/ForumList';
import AddForum from 'views/ForumList/addForum';
import EditForum from 'views/ForumList/editForum';
import LiveSession from 'views/liveSession';
import LiveSessionList from 'views/liveSessionList';
import ManageContent from 'views/ManageContent';
import AddContent from 'views/ManageContent/AddContent';
import EditContent from 'views/ManageContent/EditContent';
import Profile from 'views/Profile';
import QuestionBankCreate from 'views/QuestionBank/Create';
import SuperUser from 'views/superUser';
import SystemUsersCreate from 'views/systemUsersCreate';
import Topics from 'views/Topics';
import AddTopic from 'views/Topics/addTopic';
import EditTopic from 'views/Topics/editTopic';

// sample page routing
const Homepage = Loadable(lazy(() => import('views/homepage')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Homepage />
        },
        {
            path: '/super-user',
            element: <SuperUser />,
            children: [
                {
                    path: '/super-user/users-create',
                    element: <SystemUsersCreate />
                },
                {
                    path: '/super-user/acl',
                    element: <Acl />
                },
                {
                    path: '/super-user/forums',
                    element: <ForumsAclList />
                },
                {
                    path: '/super-user/acl/edit/:authorityId',
                    element: <AclEdit />
                }
            ]
        },
        {
            path: '/forum-list',
            element: <ForumList />
        },
        {
            path: '/forum-acl/:forumID',
            element: <ForumAcl />
        },
        {
            path: '/forum-acl/:forumID/edit/:authorityId',
            element: <ForumAclEdit />
        },
        {
            path: '/forum-topics/:forumID',
            element: <Topics />
        },
        {
            path: '/forum-faq/:forumID',
            element: <Faq />
        },
        {
            path: '/manage-faq/:forumID',
            element: <ManageFaq />
        },
        {
            path: '/add-faq/:forumID',
            element: <AddFaq />
        },
        {
            path: '/edit-faq/:forumID/:faqID',
            element: <EditFaq />
        },
        {
            path: '/content-and-discussion/:forumID/:topicID',
            element: <ContentAndDiscussion />
        },
        {
            path: '/forum-topics/:forumID/add-live-session',
            element: <AddLiveSession />
        },
        {
            path: '/forum-topics/:forumID/live-session-list',
            element: <LiveSessionList />
        },
        {
            path: '/forum-topics/:forumID/live-session/:liveSessionId',
            element: <LiveSession />
        },
        {
            path: '/manage-content/:forumID/:topicID',
            element: <ManageContent />
        },
        {
            path: '/add-content/:forumID/:topicID',
            element: <AddContent />
        },
        {
            path: '/edit-content/:forumID/:conID',
            element: <EditContent />
        },
        {
            path: '/add-topic/:forumID',
            element: <AddTopic />
        },
        {
            path: '/edit-topic/:forumID/:topicID',
            element: <EditTopic />
        },
        {
            path: '/add-forum',
            element: <AddForum />
        },
        {
            path: '/edit-forum/:forumID',
            element: <EditForum />
        },
        {
            path: '/question-bank-create/:forumID/:topicID',
            element: <QuestionBankCreate />
        },
        {
            path: '/feedback',
            element: <Feedback />
        },
        {
            path: '/profile',
            element: <Profile />
        }
    ]
};

export default MainRoutes;
