import { Grid, Typography } from '@mui/material';
import ConfirmButton from 'components/common/ConfirmButton';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import axiosServices from 'utils/axiosServices';
import { checkForumAccess, checkNull } from 'utils/Helpers';
import langString from 'utils/langString';

type DeletePropsType = {
    primary: boolean;
    comID: number;
    forumID: number;
    authorID: number;
    refreshDiscussion?: () => void;
    refreshReplies?: () => void;
};
export default function Delete({ primary, comID, forumID, authorID, refreshDiscussion, refreshReplies }: DeletePropsType) {
    const [access, setAccess] = useState(false);
    const dispatch = useDispatch();
    const { user } = useAuth();
    const deleteComment = async () => {
        if (forumID && comID) {
            if (access) {
                let reqUrl = `forum-management/forums/${forumID}/replies/${comID}/delete/`;
                if (primary) {
                    reqUrl = `forum-management/forums/${forumID}/comments/${comID}/delete/`;
                }
                axiosServices
                    .delete(reqUrl)
                    .then((res) => {
                        if (res.status === 200 && refreshDiscussion) {
                            refreshDiscussion();
                        }
                        if (res.status === 200 && refreshReplies) {
                            refreshReplies();
                        }
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: 'Comment Deleted',
                            variant: 'alert',
                            alertSeverity: 'success'
                        });
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            }
        }
    };

    useEffect(() => {
        const init = async () => {
            const haveAccess = await checkForumAccess(forumID, 'primary-comment', ['delete']);
            const haveOwnAccess = await checkForumAccess(forumID, 'primary-comment', ['delete_own']);

            const userId = checkNull(user?.id);

            if (haveAccess || (haveOwnAccess && userId.toString() === authorID.toString())) {
                setAccess(true);
            }
        };
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forumID]);

    return (
        <Grid container xs={6} sm={1} textAlign="center">
            {access && (
                <>
                    <Grid item xs={12}>
                        <ConfirmButton
                            iconOnly
                            variant="text"
                            buttonColor="error"
                            subTitle="Delete this Comment!"
                            confirmed={() => deleteComment()}
                        />
                    </Grid>
                    <Grid item xs={12} marginBottom={1}>
                        <Typography variant="body1" color="red">
                            {langString('delete')}
                        </Typography>
                    </Grid>
                </>
            )}
        </Grid>
    );
}
