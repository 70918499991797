// third-party
// assets
import { IconDashboard, IconHelp, IconSitemap, IconUsers } from '@tabler/icons';
import { FormattedMessage } from 'react-intl';

// constant
const icons = {
    IconDashboard,
    IconHelp,
    IconSitemap,
    IconUsers
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'forum-list',
            title: <FormattedMessage id="forumlist" />,
            type: 'item',
            url: '/forum-list',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'feedback',
            title: <FormattedMessage id="feedback" />,
            type: 'item',
            url: '/feedback',
            icon: icons.IconHelp,
            breadcrumbs: false
        }
    ]
};
export const superUser = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'user-create',
            title: <FormattedMessage id="User Create" />,
            type: 'item',
            url: '/super-user/users-create',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'acl',
            title: <FormattedMessage id="ACL" />,
            type: 'item',
            url: '/super-user/acl',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'forum-acl',
            title: <FormattedMessage id="Forum ACL" />,
            type: 'item',
            url: '/super-user/forums',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default other;
