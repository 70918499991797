import defaultImage from 'assets/images/placeholder/default.png';
import { AclType } from 'types/UserDataTypes';
import { BASEURL } from './Constants';
import langString from './langString';

// eslint-disable-next-line consistent-return
export async function fetchAuthorizedData(url: string, postData?: any) {
    try {
        const token = localStorage.getItem('serviceToken');
        return await fetch(BASEURL + url, {
            method: 'POST',
            headers: {
                Accept: 'application/vnd.api+json',
                'Content-Type': 'application/vnd.api+json',
                Authorization: `JWT ${token}`
            },
            body: JSON.stringify(postData)
        }).then((data) => data.json());
    } catch (error) {
        console.log(error);
    }
}

export const fullFilePath = (picPath: string) => {
    if (!picPath) {
        return defaultImage;
    }
    if (picPath.includes('http')) {
        return picPath;
    }
    return `${BASEURL}${picPath
        ?.split('/')
        .filter((v) => v !== '')
        .join('/')}`;
};

export const checkSystemAcl = (module: string, points: string[], userAcl: AclType[]) => {
    let author: boolean = false;
    if (userAcl) {
        userAcl.forEach((acl: AclType) => {
            if (acl.attributes.map.module.name === module && points.includes(acl.attributes.map.point.name)) {
                author = acl.attributes.access;
            }
        });
    }
    return author;
};

export const checkSystemMultipleAcl = (module: string, points: string[], userAcl: AclType[]) => {
    const access: any = {};
    if (userAcl) {
        userAcl.forEach((acl: AclType) => {
            if (acl.attributes.map.module.name === module) {
                if (points) {
                    points.forEach((item: string) => {
                        if (item === acl.attributes.map.point.name) {
                            access[item] = acl.attributes.access;
                        }
                    });
                }
            }
        });
    }
    return access;
};

export const checkForumAccess = async (forumID: number, module: string, points: string[]) => {
    let haveAccess = false;
    const response = await fetchAuthorizedData(`users/permissions/forum-acl/${forumID}/`);
    if (response && response.data) {
        response.data.forEach((acl: any) => {
            if (acl.attributes.map.forum_module.module.name === module && points.includes(acl.attributes.map.point.name)) {
                haveAccess = acl.attributes.access;
            }
        });
    }
    return haveAccess;
};

export const checkForumMultipleAccess = async (forumID: number, module: string, points: string[]) => {
    const access: any = {};
    const response = await fetchAuthorizedData(`users/permissions/forum-acl/${forumID}/`);
    if (response && response.data) {
        response.data.forEach((acl: any) => {
            if (acl.attributes.map.forum_module.module.name === module) {
                if (points) {
                    points.forEach((item: string) => {
                        if (item === acl.attributes.map.point.name) {
                            access[item] = acl.attributes.access;
                        }
                    });
                }
            }
        });
    }
    return access;
};

export const checkNullInfo = (info: string | null | undefined) => {
    if (info) {
        return info;
    }
    return langString('datanotavailable');
};

export const validateFormFields = (fields: string[], values: any[]) => {
    const errors: any = { valid: true };
    if (fields.length) {
        fields.forEach((item: string, index: number) => {
            if (values[index]) {
                errors[item] = {
                    required: false,
                    message: 'Good'
                };
            } else {
                errors.valid = false;
                const camelCase = item.replace(/([A-Z])/g, ' $1');
                const sentence = camelCase.charAt(0).toUpperCase() + camelCase.slice(1);
                errors[item] = {
                    required: true,
                    message: `${sentence} is required`
                };
            }
        });
    }
    return errors;
};

export const getImagePath = (url: string | null | undefined) => {
    if (url) {
        const item = url.split('/');
        item?.shift();
        return `${process.env.REACT_APP_SERVICE_URL}/${item?.join('/')}`;
    }
    return '';
};

export const checkNull = (info: number | string | undefined) => {
    if (info) {
        let x = 0;
        if (typeof info === 'string') {
            x = parseInt(info, 10);
        } else if (typeof info === 'number') {
            x = info;
        }
        return x;
    }
    return 0;
};
