import { Box, Button, CardProps, Container, Typography } from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import langString from 'utils/langString';

export interface EndSessionModalT extends CardProps {
    handleClose: () => void;
    handleClick: (url: string, endSession?: boolean) => Promise<void>;
    link: string;
}

export const EndSessionModal = React.forwardRef(({ handleClose, handleClick, link }: EndSessionModalT, ref: React.Ref<HTMLDivElement>) => (
    <Container ref={ref} tabIndex={-1}>
        <MainCard
            sx={{
                position: 'absolute',
                width: { xs: 300, sm: 400, md: 400, lg: 400 },
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}
        >
            <Box padding={2}>
                <Typography variant="body1" color="red" marginBottom={1}>
                    This is not safe as it may not save session recording. To ensure recording end the session from panel.
                </Typography>
                <Typography variant="body1" color="red" marginBottom={3}>
                    Still want to end?
                </Typography>
                <Box textAlign="center">
                    <Button
                        onClick={() => handleClick(link, true)}
                        variant="contained"
                        sx={{ paddingY: '13px', borderRadius: '0px', marginRight: 2 }}
                    >
                        {`${langString('end')}`}
                    </Button>
                    <Button onClick={handleClose} variant="contained" sx={{ paddingY: '13px', borderRadius: '0px' }}>
                        {`${langString('cancel')}`}
                    </Button>
                </Box>
            </Box>
        </MainCard>
    </Container>
));
