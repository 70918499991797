import { Grid, Typography } from '@mui/material';

type forumsTable = {
    button?: JSX.Element;
    children: JSX.Element;
    title: string;
};

export default function ForumsTable({ title, button, children }: forumsTable) {
    return (
        <>
            <Grid container xs={12} justifyContent="center">
                <Grid item xs={8}>
                    <Typography variant="h2" color="initial" textAlign="center" marginY="20px">
                        {title}
                    </Typography>
                    {button}
                    <Grid container xs={12} spacing={3} justifyItems="center" alignItems="center" marginTop="20px">
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
