import { Box, Grid, Typography } from '@mui/material';
import DataLoading from 'components/common/DataLoading';
import { useEffect, useState } from 'react';
import axiosServices from 'utils/axiosServices';
import { checkNullInfo } from 'utils/Helpers';
import langString from 'utils/langString';
import Action from './Action';
import Counter from './Counter';
import Delete from './Delete';
import Meta from './Meta';
import PostComment from './PostComment';

type ReplyType = {
    type: string;
    id: number;
    attributes: {
        comment: string;
        created_by: {
            id: number;
            username: string;
            user_authority: string;
            display_name: string;
        };
        updated_by: {
            id: number;
            username: string;
            user_authority: string;
            display_name: string;
        };
        created_at: string;
        updated_at: string;
        deleted_at: null | string;
        parent: {
            id: number;
            comment: string;
            created_by: {
                id: number;
                username: string;
                user_authority: string;
                display_name: string;
            };
            updated_by: {
                id: number;
                username: string;
                user_authority: string;
                display_name: string;
            };
            created_at: string;
            updated_at: string;
            deleted_at: null | string;
            total_likes: number;
            liked: boolean;
            total_replies: number;
        };
        replied_to: {
            id: number;
            comment: string;
            created_by: {
                id: number;
                username: string;
                user_authority: string;
                display_name: string;
            };
            updated_by: {
                id: number;
                username: string;
                user_authority: string;
                display_name: string;
            };
            deleted_at: null | string;
        };
        total_likes: number;
        liked: boolean;
    };
};

type RepliesPropsType = {
    forumID: number;
    comID: number;
    authorID: number;
};
export default function Replies({ forumID, comID, authorID }: RepliesPropsType) {
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState<ReplyType[]>([]);

    const getAllReplies = () => {
        if (forumID && comID) {
            axiosServices
                .post(`forum-management/forums/${forumID}/comments/${comID}/replies/`)
                .then((res) => {
                    if (res.status === 200 && res.data.data) {
                        setComments(res.data.data);
                        setLoading(false);
                    }
                })
                .catch((e: any) => {
                    console.log(e);
                });
        }
    };

    useEffect(() => {
        const init = () => {
            if (forumID && comID) {
                axiosServices
                    .post(`forum-management/forums/${forumID}/comments/${comID}/replies/`)
                    .then((res) => {
                        if (res.status === 200 && res.data.data) {
                            setComments(res.data.data);
                            setLoading(false);
                        }
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            }
        };
        init();
    }, [forumID, comID]);

    return (
        <>
            <Box>
                <PostComment commentID={comID} repliedTO={authorID} primary={false} refreshReplies={() => getAllReplies()} />
            </Box>
            <Box sx={{ marginLeft: 3 }}>
                <Typography variant="h5" margin={1} color="#7f8c8d">
                    {langString('replies')}
                </Typography>
                {loading && <DataLoading />}
                {!loading && comments.length ? (
                    comments.map((com: ReplyType) => (
                        <Grid key={com.id} container>
                            <Grid item xs={9} sm={10} sx={{ border: '1px solid #95a5a6', marginTop: 0.5 }}>
                                <Meta
                                    author={com.attributes?.created_by?.display_name}
                                    role={com.attributes?.created_by?.user_authority}
                                    createdAt={com.attributes?.created_at}
                                />
                                <Typography variant="body1" color="#616161" marginX={1} marginY="5px" fontSize={16}>
                                    <strong>@{checkNullInfo(com.attributes?.parent?.created_by?.display_name)}</strong>{' '}
                                    {checkNullInfo(com.attributes?.comment)}
                                </Typography>
                                <Action
                                    open={false}
                                    setOpen={() => {}}
                                    primary={false}
                                    refreshReplies={() => getAllReplies()}
                                    liked={com.attributes?.liked}
                                    forumID={forumID}
                                    comID={com.id}
                                    totalReplies={0}
                                />
                            </Grid>
                            <Counter primary={false} likes={com.attributes?.total_likes} replies={0} />
                            <Delete
                                primary={false}
                                refreshReplies={() => getAllReplies()}
                                forumID={forumID}
                                comID={com.id}
                                authorID={com.attributes.created_by.id}
                            />
                        </Grid>
                    ))
                ) : (
                    <Typography variant="h6" margin={3}>
                        {langString('datanotavailable')}
                    </Typography>
                )}
            </Box>
        </>
    );
}
