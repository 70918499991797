import { NavItemType } from 'types';
import other, { superUser } from './other';

// ==============================|| MENU ITEMS ||============================== //

// const menuItems: { items: NavItemType[] } = {
//     items: [other]
// };

// export default menuItems;

type GetMenuItemsT = {
    items: NavItemType[];
};

export default function getMenuItems(isSuperUser: boolean | undefined): GetMenuItemsT {
    if (isSuperUser) {
        return {
            items: [superUser]
        };
    }

    return {
        items: [other]
    };
}
