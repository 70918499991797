// material-ui
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import langString from 'utils/langString';
import { useNavigate } from 'react-router-dom';

// type of TableBasic Component
export type rowsT = {
    sl: number;
    primaryComment: string;
    topicName: string;
    likes: number;
    replies: number;
    postedOn: string;
    forum_id: string;
    topic_id: string;
};
export type tableBasic = {
    rows: rowsT[];
};

// ==============================|| TABLE - BASIC ||============================== //

export default function TableBasic({ rows }: tableBasic) {
    const navigate = useNavigate();

    return (
        <MainCard sx={{ border: '1px solid gray', borderRadius: '5px' }}>
            <TableContainer>
                <Table sx={{ minWidth: 350 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{langString('sl')}</TableCell>
                            <TableCell>
                                {langString('primary')} {langString('comment')}
                            </TableCell>
                            <TableCell>{langString('name')}</TableCell>
                            <TableCell>{langString('likes')}</TableCell>
                            <TableCell>{langString('replies')}</TableCell>
                            <TableCell>{langString('postedon')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.length ? (
                            rows.map((row) => (
                                <TableRow
                                    sx={{ cursor: 'pointer' }}
                                    hover
                                    key={row.sl}
                                    onClick={() => navigate(`/content-and-discussion/${row.forum_id}/${row.topic_id}/`, { replace: true })}
                                >
                                    <TableCell>{row.sl}</TableCell>
                                    <TableCell>
                                        <Typography noWrap>
                                            {row.primaryComment.length > 30
                                                ? `${row.primaryComment.substring(0, 30)}...`
                                                : row.primaryComment}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography noWrap>{row.topicName}</Typography>
                                    </TableCell>
                                    <TableCell>{row.likes}</TableCell>
                                    <TableCell>{row.replies}</TableCell>
                                    <TableCell>
                                        <Typography noWrap>{row.postedOn}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6}>{langString('datanotavailable')}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
}
