import { Box, Button, CardProps, Container, Typography } from '@mui/material';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import langString from 'utils/langString';

export interface MeetingRecordModalT extends CardProps {
    handleClose: () => void;
    meetingRecordList: any[];
}

export const MeetingRecordModal = React.forwardRef(
    ({ handleClose, meetingRecordList }: MeetingRecordModalT, ref: React.Ref<HTMLDivElement>) => (
        <Container ref={ref} tabIndex={-1}>
            <MainCard
                sx={{
                    position: 'absolute',
                    width: { xs: 300, sm: 400, md: 400, lg: 400 },
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Box padding={2}>
                    {meetingRecordList.length > 0 ? (
                        meetingRecordList.map((val, indx) => (
                            <Typography
                                key={val.id}
                                marginY={2}
                                sx={{ textDecoration: 'none' }}
                                variant="h3"
                                color="black"
                                component="a"
                                href={val.link}
                                target="_blank"
                            >
                                {indx + 1}.Video Recording {indx + 1}
                            </Typography>
                        ))
                    ) : (
                        <Typography marginY={2} variant="body1" color="initial" marginBottom={3} textAlign="center">
                            No recording
                        </Typography>
                    )}
                    <Box textAlign="center">
                        <Button onClick={handleClose} variant="contained" sx={{ paddingY: '13px', borderRadius: '0px', marginY: 2 }}>
                            {`${langString('cancel')}`}
                        </Button>
                    </Box>
                </Box>
            </MainCard>
        </Container>
    )
);
