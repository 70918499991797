import { Container, Typography } from '@mui/material';
import langString from 'utils/langString';
import Media from './Media';

export type ContentType = {
    title: string;
    order: number;
    type: 'video' | 'audio' | 'text' | 'link' | 'image';
    text: string;
    url: string;
    picture: string;
    video: string;
    audio: string;
};
type ContentProps = {
    contents: ContentType[];
};
export default function Content({ contents }: ContentProps) {
    return (
        <>
            <Container>
                {contents.length ? (
                    contents.map((content) => (
                        <>
                            <Media
                                type={content.type}
                                text={content.text}
                                url={content.url}
                                title={content.title}
                                order={content.order}
                                picture={content.picture}
                                video={content.video}
                                audio={content.audio}
                            />
                        </>
                    ))
                ) : (
                    <Typography variant="h4" color="initial" sx={{ padding: 5, textAlign: 'center' }}>
                        {langString('datanotavailable')}
                    </Typography>
                )}
            </Container>
        </>
    );
}
