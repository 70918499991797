import {
    Box,
    Button,
    Checkbox,
    Container,
    InputLabel,
    FormControl,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    TextareaAutosize,
    FormHelperText
} from '@mui/material';
import { useFormik } from 'formik';
import axiosServices from 'utils/axiosServices';
import langString from 'utils/langString';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import DataLoading from 'components/common/DataLoading';
import FileInput from 'components/common/FileInput';
import useAuth from 'hooks/useAuth';
import { checkSystemAcl } from 'utils/Helpers';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';

export default function AddForum() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { user } = useAuth();
    const dispatch = useDispatch();

    const onSubmitHandle = async (values: any) => {
        const postData: any = {
            data: {
                type: 'Forum',
                attributes: {
                    name: values.title,
                    description: values.shortNote,
                    has_faq: values.has_faq,
                    has_live_session: values.has_live_session,
                    is_editable: values.is_editable,
                    is_deletable: values.is_deletable,
                    picture: values.picture
                }
            }
        };
        setLoading(true);
        try {
            axiosServices.post(`forum-management/forums/create/`, postData).then((res) => {
                if (res.status === 201) {
                    navigate('/forum-list', { replace: true });
                }
                setLoading(false);
            });
        } catch (error) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'Invalid Form Data',
                variant: 'alert',
                alertSeverity: 'error'
            });
        }
    };
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: '',
            shortNote: '',
            has_faq: true,
            has_live_session: false,
            is_editable: true,
            is_deletable: true,
            picture: null
        },
        validationSchema: yup.object({
            title: yup
                .string()
                .min(3, 'Too Short!')
                .max(255, 'Too Large! Can not exceed 255 charecter!')
                .required('Forum Title is required'),
            shortNote: yup.string().min(3, 'Too Short!').required('Forum Description is required')
        }),
        onSubmit: onSubmitHandle
    });

    useEffect(() => {
        if (user && user.acl) {
            if (!checkSystemAcl('forum', ['create'], user.acl)) {
                navigate('/forum-list', { replace: true });
            }
        } else {
            navigate('/', { replace: true });
        }
    }, [navigate, user]);

    return (
        <>
            {loading && <DataLoading />}
            <Container maxWidth="sm" sx={{ background: '#FFF', padding: 2, borderRadius: 5 }}>
                <Typography variant="h2" color="initial" textAlign="center" marginY={3}>
                    {`${langString('create')} ${langString('new')} ${langString('forum')}`}
                </Typography>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container xs={12} justifyContent={{ sm: 'space-between' }}>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                <FileInput fileType="image" setFile={(value: any) => formik.setFieldValue('picture', value)} />
                                <InputLabel sx={{ marginTop: 2 }} htmlFor="picture">
                                    {langString('uploadforumphoto')}
                                </InputLabel>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} textAlign={{ sm: 'right' }} marginTop={{ xs: 2, sm: 0 }}>
                            {formik.values.picture ? (
                                <div style={{ padding: 10, textAlign: 'center' }}>
                                    <img src={formik.values.picture} alt="forumphoto" width="100" />
                                </div>
                            ) : (
                                `${langString('no')} ${langString('picture')} ${langString('selected')}`
                            )}
                        </Grid>
                    </Grid>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <TextField
                            fullWidth
                            sx={{ marginY: 2 }}
                            size="small"
                            id="title"
                            name="title"
                            type="text"
                            placeholder={`${langString('forum')} ${langString('title')}:`}
                            label={
                                <>
                                    {`${langString('forum')} ${langString('title')}`}:
                                    <Typography component="span" color="red">
                                        *
                                    </Typography>
                                </>
                            }
                            value={formik.values.title}
                            onChange={(event) => formik.setFieldValue('title', event.target.value)}
                            error={formik.touched.title && Boolean(formik.errors.title)}
                            helperText={formik.touched.title && formik.errors.title}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <Box sx={{ padding: 1, border: '1px solid #DDD' }}>
                            <TextareaAutosize
                                value={formik.values.shortNote || ''}
                                onChange={(event) => formik.setFieldValue('shortNote', event.target.value)}
                                aria-label="shortNote"
                                minRows={6}
                                placeholder={langString('writeashortnoteaboutthedorum')}
                                style={{ width: '100%' }}
                            />
                            {Boolean(formik.touched.shortNote && formik.errors.shortNote) && (
                                <FormHelperText error>{formik.errors.shortNote}</FormHelperText>
                            )}
                        </Box>
                    </FormControl>
                    <Grid container justifyContent={{ sm: 'space-between' }}>
                        <Grid item xs={12} sm={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.has_faq}
                                        onChange={(event) => formik.setFieldValue('has_faq', event.target.checked)}
                                    />
                                }
                                label="Has FAQ"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.has_live_session}
                                        onChange={(event) => formik.setFieldValue('has_live_session', event.target.checked)}
                                    />
                                }
                                label="Has Live Session"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.is_editable}
                                        onChange={(event) => formik.setFieldValue('is_editable', event.target.checked)}
                                    />
                                }
                                label="Editable"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.is_deletable}
                                        onChange={(event) => formik.setFieldValue('is_deletable', event.target.checked)}
                                    />
                                }
                                label="Deletable"
                                sx={{ display: 'block' }}
                            />
                        </Grid>
                    </Grid>

                    <Box textAlign="center" marginY={1}>
                        <Button
                            color="inherit"
                            variant="contained"
                            type="reset"
                            onClick={() => {
                                formik.resetForm();
                                navigate('/forum-list', { replace: true });
                            }}
                            sx={{ marginRight: 1 }}
                        >
                            {langString('cancel')}
                        </Button>
                        <Button variant="contained" type="submit">
                            {langString('save')}
                        </Button>
                    </Box>
                </form>
            </Container>
        </>
    );
}
