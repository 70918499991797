// material-ui
import { Grid } from '@mui/material';
import DataLoading from 'components/common/DataLoading';
import { useEffect, useState } from 'react';
import axiosServices from 'utils/axiosServices';
import Filter from './Filter';
import TableBasic, { rowsT } from './TableBasic';

export default function Dashboard() {
    const [sequence, setSequence] = useState<string>('Highest-Likes');
    const [period, setPeriod] = useState<string>('1-week');
    const [rows, setRows] = useState<rowsT[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const postData = {
            data: {
                type: 'Comment',
                attributes: {
                    sequence,
                    period
                }
            }
        };
        try {
            setLoading(true);
            axiosServices
                .post(`forum-management/dashboard/comments/`, postData)
                .then((res) => {
                    if (res.status === 200 && res.data.data) {
                        const contentList: rowsT[] = [];
                        res.data.data.forEach((item: any, index: number) => {
                            contentList.push({
                                sl: index + 1,
                                primaryComment: item.attributes.comment,
                                topicName: item.attributes.topic,
                                likes: item.attributes.total_likes,
                                replies: item.attributes.total_replies,
                                postedOn: new Date(item.attributes.created_at).toLocaleString(),
                                forum_id: item.attributes.forum_id,
                                topic_id: item.attributes.topic_id
                            });
                        });
                        setRows(contentList);
                        setLoading(false);
                    }
                })
                .catch((e: any) => {
                    setRows([]);
                    setLoading(false);
                    console.log(e);
                });
        } catch (error) {
            setRows([]);
            console.log(error);
            setLoading(false);
        }
    }, [sequence, period]);

    return (
        <>
            <Grid container spacing={1} marginY="3px">
                <Grid item xs={12}>
                    <Filter selectedPeriod={period} selectedSequence={sequence} setSequence={setSequence} setPeriod={setPeriod} />
                </Grid>
                <Grid item xs={12}>
                    {loading && <DataLoading />}
                    <TableBasic rows={rows} />
                </Grid>
            </Grid>
        </>
    );
}
