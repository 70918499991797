import isk from 'assets/images/mpower/isk.png';
import mpower from 'assets/images/mpower/mpower.png';
import oik from 'assets/images/mpower/oik.png';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    footerWrapper: {
        position: 'relative',
        height: 100
    },
    footer: {
        position: 'absolute',
        width: '98%',
        bottom: 5,
        textAlign: 'center'
    },
    logoItem: {
        marginLeft: 20,
        width: 50,
        maxHeight: 30
    }
});
export default function MinimalFooter() {
    const classes = useStyles();

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className={classes.footerWrapper}>
                <div className={classes.footer}>
                    <img className={classes.logoItem} src={oik} alt="oik" />
                    <img className={classes.logoItem} src={mpower} alt="mpower" />
                    <img className={classes.logoItem} src={isk} alt="isk" />
                </div>
            </Grid>
        </Grid>
    );
}
