import { Button, Grid, Pagination, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/system';
import AccessDenied from 'components/common/AccessDenied';
import ConfirmButton from 'components/common/ConfirmButton';
import DataLoading from 'components/common/DataLoading';
import ReOrder, { ReOrderItemType } from 'components/common/ReOrder';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { SNACKBAR_OPEN } from 'store/actions';
import { AccessType } from 'types/UserDataTypes';
import axiosServices from 'utils/axiosServices';
import { checkForumAccess, checkForumMultipleAccess, checkSystemAcl } from 'utils/Helpers';
import langString from 'utils/langString';

type TopicType = {
    id: number;
    topicTitle: string;
    views: number;
    discussions: number;
    action: string;
    isDelete?: boolean;
    isEdit?: boolean;
};
export default function Topics() {
    const [loading, setLoading] = useState(true);
    const [hasFaq, setHasFaq] = useState(false);
    const [faqAccess, setFaqAccess] = useState(false);
    const [hasLive, setHasLive] = useState(false);
    const [liveAccess, setLiveAccess] = useState(false);
    const [access, setAccess] = useState<AccessType>({ read: false });
    const [title, setTitle] = useState('');
    const [rows, setRows] = useState<TopicType[]>([]);
    const [reOrders, setReOrders] = useState<ReOrderItemType[]>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const { forumID } = useParams();
    const { user } = useAuth();
    const dispatch = useDispatch();

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const topicDelete = (topicID: number) => {
        if (access.delete && forumID) {
            try {
                axiosServices.delete(`forum-management/forums/${forumID}/topics/${topicID}/delete/`).then((res) => {
                    if (res.status === 200) {
                        const allTopics: TopicType[] = [...rows];
                        const updatedItems = allTopics.filter((fm) => fm.id !== topicID);
                        setRows(updatedItems);
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: 'Topic Deleted',
                            variant: 'alert',
                            alertSeverity: 'success'
                        });
                    }
                });
            } catch (error) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: error,
                    variant: 'alert',
                    alertSeverity: 'success'
                });
            }
        }
    };

    const reorderList = (orderedItems: ReOrderItemType[]) => {
        const orderList = orderedItems.map(({ description, ...rest }) => rest);
        const postData = {
            data: {
                type: 'Topic',
                attributes: {
                    objects: orderList
                }
            }
        };
        setLoading(true);
        axiosServices.post(`forum-management/forums/${forumID}/topics/re-order/`, postData).then((res) => {
            if (res.status === 200) {
                const topicList: TopicType[] = [];
                res.data.data.forEach((topic: any) =>
                    topicList.push({
                        id: topic.id,
                        topicTitle: topic.attributes.name,
                        views: topic.attributes.views,
                        discussions: topic.attributes.primary_discussions,
                        action: `/content-and-discussion/${forumID}/${topic.id}`,
                        isDelete: topic.attributes.is_deletable,
                        isEdit: topic.attributes.is_editable
                    })
                );
                setRows(topicList);
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'Topic Reordered',
                    variant: 'alert',
                    alertSeverity: 'success'
                });
            }
            setLoading(false);
        });
    };

    // server request
    useEffect(() => {
        const init = async () => {
            if (user && user.acl) {
                if (checkSystemAcl('forum', ['read'], user.acl) && forumID) {
                    const faqAccessCheck = await checkForumAccess(parseInt(forumID, 10), 'faq', ['create', 'update', 'delete']);
                    if (faqAccessCheck) {
                        setFaqAccess(true);
                    }
                    const liveAccessCheck = await checkForumAccess(parseInt(forumID, 10), 'live-session', ['read']);
                    if (liveAccessCheck) {
                        setLiveAccess(true);
                    }
                    const haveAccess = await checkForumMultipleAccess(parseInt(forumID, 10), 'topic', [
                        'read',
                        'create',
                        'update',
                        'delete'
                    ]);
                    if (haveAccess && haveAccess.read) {
                        setAccess(haveAccess);
                        axiosServices.post(`forum-management/forums/${forumID}/topics/?page%5Bnumber%5D=${page}`).then((res) => {
                            if (res.status === 200 && res.data.data) {
                                setTotalPages(res.data?.meta?.pagination?.pages);
                                const topicList: TopicType[] = [];
                                res.data.data.forEach((topic: any) =>
                                    topicList.push({
                                        id: topic.id,
                                        topicTitle: topic.attributes.name,
                                        views: topic.attributes.views,
                                        discussions: topic.attributes.primary_discussions,
                                        action: `/content-and-discussion/${forumID}/${topic.id}`,
                                        isDelete: topic.attributes.is_deletable,
                                        isEdit: topic.attributes.is_editable
                                    })
                                );
                                setRows(topicList);
                                setLoading(false);
                                const orderItemList: ReOrderItemType[] = [];
                                res.data.data.forEach((item: any) =>
                                    orderItemList.push({
                                        id: item.id,
                                        description: item.attributes.name,
                                        order: item.attributes.order
                                    })
                                );
                                setReOrders(orderItemList);
                            }
                        });
                    }
                }
            }
            setLoading(false);
        };
        init();
    }, [forumID, user, page]);

    useEffect(() => {
        const init = async () => {
            if (user && user.acl) {
                if (checkSystemAcl('forum', ['read'], user.acl) && forumID) {
                    axiosServices.post(`forum-management/forums/${forumID}/details/`).then((res) => {
                        if (res.status === 200 && res.data.data) {
                            setHasFaq(res.data.data.attributes?.has_faq);
                            setHasLive(res.data.data.attributes?.has_live_session);
                            setTitle(res.data.data.attributes.name);
                        }
                    });
                }
            }
        };
        init();
    }, [forumID, user]);

    let managefaq: string | JSX.Element = '';
    if (hasFaq) {
        if (faqAccess) {
            managefaq = (
                <Grid item xs={6} sm={4} md={3}>
                    <Button component={Link} to={`/manage-faq/${forumID}`} variant="contained" sx={{ margin: 1 }}>
                        {langString('managefaq')}
                    </Button>
                </Grid>
            );
        } else {
            managefaq = (
                <Grid item xs={6} sm={4} md={3}>
                    <Button component={Link} to={`/forum-faq/${forumID}`} variant="contained" sx={{ margin: 1 }}>
                        {langString('faq')}
                    </Button>
                </Grid>
            );
        }
    } else {
        managefaq = '';
    }

    return (
        <>
            {loading && <DataLoading />}
            {!loading && !access.read && <AccessDenied />}
            {!loading && access.read && (
                <Box>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={12} md={8}>
                            <Typography variant="h2" color="initial" textAlign="center" marginY="20px">
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        {user && user.acl?.length && user.acl[0]?.attributes?.authority?.name === 'system-admin' && (
                            <Grid item xs={6} sm={4} md={3}>
                                <Button component={Link} to={`/forum-acl/${forumID}`} variant="contained" sx={{ margin: 1 }}>
                                    {langString('forumacl')}
                                </Button>
                            </Grid>
                        )}

                        {managefaq}

                        {hasLive && liveAccess && (
                            <Grid item xs={6} sm={4} md={3}>
                                <Button
                                    component={Link}
                                    to={`/forum-topics/${forumID}/live-session-list/`}
                                    variant="contained"
                                    sx={{ margin: 1 }}
                                >
                                    {langString('livesessionlist')}
                                </Button>
                            </Grid>
                        )}
                        {access.create && (
                            <Grid item xs={6} sm={4} md={3}>
                                <Button component={Link} to={`/add-topic/${forumID}`} variant="contained" sx={{ margin: 1 }}>
                                    {langString('addnewtopic')}
                                </Button>
                            </Grid>
                        )}
                        {access.update && reOrders.length && (
                            <Grid item xs={6} sm={4} md={3}>
                                <ReOrder
                                    buttonSX={{ marginLeft: 1, marginTop: '5px' }}
                                    items={reOrders}
                                    reorderList={(items: ReOrderItemType[]) => reorderList(items)}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid container xs={12} spacing={3} justifyItems="center" alignItems="center" marginTop="20px">
                        <Grid item sm={12} xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                {langString('topic')} {langString('title')}
                                            </TableCell>
                                            <TableCell align="center">{langString('views')}</TableCell>
                                            <TableCell align="center">{langString('discussion')}</TableCell>
                                            <TableCell align="center">{langString('action')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.length ? (
                                            rows.map((row) => (
                                                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell component="th" scope="row">
                                                        {row.topicTitle}
                                                    </TableCell>
                                                    <TableCell align="center">{row.views}</TableCell>
                                                    <TableCell align="center">{row.discussions}</TableCell>
                                                    <TableCell align="center">
                                                        {access.read && (
                                                            <Button
                                                                size="small"
                                                                component={Link}
                                                                to={row.action}
                                                                variant="contained"
                                                                sx={{ margin: 1 }}
                                                            >
                                                                {langString('view')}
                                                            </Button>
                                                        )}
                                                        {access.update && row.isEdit && (
                                                            <Button
                                                                size="small"
                                                                component={Link}
                                                                to={`/edit-topic/${forumID}/${row.id}`}
                                                                variant="contained"
                                                                sx={{ margin: 1 }}
                                                                color="success"
                                                            >
                                                                {langString('edit')}
                                                            </Button>
                                                        )}
                                                        {access.delete && row.isDelete && (
                                                            <ConfirmButton
                                                                buttonColor="error"
                                                                subTitle="Delete this topic!"
                                                                confirmed={() => topicDelete(row.id)}
                                                            />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <Typography variant="h4" color="initial" textAlign="center">
                                                {langString('datanotavailable')}
                                            </Typography>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} justifyContent="center">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center">
                            <Stack spacing={2} sx={{ padding: 1, borderRadius: 5, margin: 2 }}>
                                <Pagination count={totalPages} page={page} onChange={handleChange} />
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
}
