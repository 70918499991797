import { Box, CircularProgress, Grid, Pagination, Stack } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import axiosServices from 'utils/axiosServices';
import CustomizedTables from './CustomizedTables';

export type UserAuthorities = {
    id: string;
    name: string;
};

export default function ForumsAclList() {
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [userAuthorities, setUserAuthorities] = useState<UserAuthorities[]>([]);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        const getUserAuthorities = async () => {
            const usersRoles: AxiosResponse<any> = await axiosServices.post(`/forum-management/forums/?page%5Bnumber%5D=${page}`);
            setTotalPages(usersRoles.data?.meta?.pagination?.pages);
            const authorities = usersRoles.data.data.map((item: any) => ({ id: item.id, name: item.attributes.name }));
            setUserAuthorities(authorities);
        };
        getUserAuthorities();
    }, [page]);

    return (
        <>
            {userAuthorities.length > 0 ? (
                <>
                    <CustomizedTables userAuthorities={userAuthorities} />
                    <Grid container spacing={1} justifyContent="center">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center">
                            <Stack spacing={2} sx={{ padding: 1, borderRadius: 5, margin: 2 }}>
                                <Pagination count={totalPages} page={page} onChange={handleChange} />
                            </Stack>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Box textAlign="center">
                    <CircularProgress />
                </Box>
            )}
        </>
    );
}
