import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Select, MenuItem } from '@mui/material';
import langString from 'utils/langString';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

export type ReOrderItemType = {
    id: number | string;
    order: number;
    description: number;
};
type ReOrderPropsType = {
    items: ReOrderItemType[];
    reorderList: (items: ReOrderItemType[]) => void;
    buttonSX?: any;
};
export default function ReOrder({ items, reorderList, buttonSX }: ReOrderPropsType) {
    const [orders, setOrders] = useState<ReOrderItemType[]>([]);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const changeOrder = (order: number, itemID: number | string) => {
        const allItems = [...items];
        allItems.forEach((item) => {
            if (item.id === itemID && item.order !== order) {
                item.order = order;
            }
        });
        setOrders(allItems);
    };

    const updateOrder = () => {
        if (orders.length) {
            reorderList(orders);
        }
        handleClose();
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleOpen} sx={buttonSX || { maring: 0 }}>
                {langString('reorder')}
            </Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-description" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography variant="h2">{langString('reorderitems')}</Typography>
                    <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'scroll-y' }}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{langString('order')}</TableCell>
                                    <TableCell align="center">{langString('description')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.length ? (
                                    items.map((row) => (
                                        <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                <Select
                                                    labelId={`${row.id}-select-label`}
                                                    id={`${row.id}-select`}
                                                    label={langString('selectorder')}
                                                    value={row.order}
                                                    onChange={(event: any) => changeOrder(event.target.value, row.id)}
                                                >
                                                    {[...Array(items.length)].map((el: any, index: number) => (
                                                        <MenuItem value={index + 1}>{index + 1}</MenuItem>
                                                    ))}
                                                </Select>
                                            </TableCell>
                                            <TableCell align="center">{row.description}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <Typography variant="h4" color="initial" textAlign="center">
                                        {langString('datanotavailable')}
                                    </Typography>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button sx={{ marginTop: 2, float: 'right' }} variant="contained" color="success" onClick={() => updateOrder()}>
                        {langString('update')}
                    </Button>
                </Box>
            </Modal>
        </>
    );
}
