import { Box, Button, CardProps, Container, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SNACKBAR_OPEN } from 'store/actions';
import MainCard from 'ui-component/cards/MainCard';
import axiosServices from 'utils/axiosServices';
import langString from 'utils/langString';
import HeaderDataTable from './HeaderDataTable';
import RowsDataTable, { rowsDataTable } from './RowsDataTable';

export interface QuestionBankT extends CardProps {
    handleClose: () => void;
    formik?: any;
    rows: rowsDataTable[];
    setRows: React.Dispatch<React.SetStateAction<rowsDataTable[]>>;
}

export const QuestionBank = React.forwardRef(({ handleClose, formik, rows, setRows }: QuestionBankT, ref: React.Ref<HTMLDivElement>) => {
    const { forumID, liveSessionId } = useParams();
    const dispatch = useDispatch();
    const handleChange = (event: any, questionId: number) => {
        const rowsClone = _.cloneDeep(rows);
        const rowIndx = rowsClone.findIndex((row) => row.question === questionId);
        rowsClone[rowIndx].answered = event.target.checked;
        setRows(rowsClone);
    };

    const handleClick = async () => {
        const res = await axiosServices.post(`forum-management/forums/${forumID}/sessions/${liveSessionId}/questions/update/`, {
            data: {
                type: 'QuestionBank',
                attributes: {
                    objects: rows
                }
            }
        });
        if (res.status === 200) {
            handleClose();
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'Update successful',
                variant: 'alert',
                alertSeverity: 'success'
            });
        }
    };
    const allRowsData = rows.map((row) => (
        <RowsDataTable
            key={row.question}
            question={row.question}
            comment={row.comment}
            answered={row.answered}
            handleChange={handleChange}
        />
    ));

    return (
        <Container ref={ref} tabIndex={-1}>
            <MainCard
                sx={{
                    position: 'absolute',
                    width: { xs: 300, sm: 700, md: 800, lg: 800 },
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <Grid container xs={12} spacing={3} justifyContent="center" alignItems="center" marginTop="20px">
                    <>
                        <HeaderDataTable />
                        {allRowsData.length > 0 ? (
                            allRowsData
                        ) : (
                            <Grid item xs={12} textAlign="center">
                                <Typography variant="h3" color="initial" display="block">
                                    No data found
                                </Typography>
                            </Grid>
                        )}
                    </>
                    {allRowsData.length > 0 ? (
                        <Box>
                            <Button type="submit" onClick={handleClick} variant="contained" sx={{ paddingY: '13px', marginTop: 5 }}>
                                {`${langString('complete')}`}
                            </Button>
                        </Box>
                    ) : (
                        <Box>
                            <Button type="submit" onClick={handleClose} variant="contained" sx={{ paddingY: '13px', marginTop: 5 }}>
                                {`${langString('cancel')}`}
                            </Button>
                        </Box>
                    )}
                </Grid>
            </MainCard>
        </Container>
    );
});
