import { Box, Grid, Typography } from '@mui/material';
import { checkNullInfo } from 'utils/Helpers';
import langString from 'utils/langString';

type MetaPropsType = {
    author: string;
    role: string;
    createdAt: string;
};
export default function Meta({ author, role, createdAt }: MetaPropsType) {
    return (
        <Grid container justifyContent="space-between" alignItems="center" sx={{ backgroundColor: '#e0e0e0' }}>
            <Box display="flex">
                <Typography variant="h3" color="#616161" marginX={1} marginY="5px">
                    {checkNullInfo(author)}
                </Typography>
                <Typography variant="h5" color="red" marginX={1} marginY="5px">
                    {checkNullInfo(langString(role))}
                </Typography>
            </Box>
            <Box>
                <Typography variant="subtitle2" color="#616161" fontSize={12} marginX={1} marginY="5px">
                    {langString('posted')} {langString('on')}: <strong>{createdAt && new Date(createdAt).toLocaleDateString()}</strong>
                </Typography>
            </Box>
        </Grid>
    );
}
