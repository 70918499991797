import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import GuestPage from 'views/guestPages';
import GuestContent from 'views/guestPages/Content';
import GuestTopics from 'views/guestPages/Topic';

// login routing
const LandingPage = Loadable(lazy(() => import('views/landing')));
const AuthLogin = Loadable(lazy(() => import('views/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('views/authentication/Register')));
const AuthForgotPassword = Loadable(lazy(() => import('views/authentication/ForgotPassword')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/landing',
            element: <LandingPage />
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/guest',
            element: <GuestPage />
        },
        {
            path: '/guest-forum-topics/:forumID',
            element: <GuestTopics />
        },
        {
            path: '/guest-forum-content/:forumID/:topicID',
            element: <GuestContent />
        }
    ]
};

export default LoginRoutes;
