import { Box, Button, Container, Grid, Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axiosServices from 'utils/axiosServices';
import { checkForumAccess, checkForumMultipleAccess, checkNullInfo, checkSystemAcl, fullFilePath } from 'utils/Helpers';
import langString from 'utils/langString';
import Content, { ContentType } from './content';
import PostComment from './Comment/PostComment';
import AccessDenied from 'components/common/AccessDenied';
import DataLoading from 'components/common/DataLoading';
import Discussion from './Discussion';
import { CommentType } from './Comment';
import { AccessType } from 'types/UserDataTypes';

export default function ContentAndDiscussion() {
    const [loading, setLoading] = useState(true);
    const [bankAccess, setBankAccess] = useState(false);
    const [bankSupport, setBankSupport] = useState(false);
    const [contentAccess, setContentAccess] = useState<AccessType>({ read: false });
    const [commentAccess, setCommentAccess] = useState<AccessType>({ read: false });
    const [discussionAccess, setDiscussionAccess] = useState<AccessType>({ read: false });
    const [contents, setContents] = useState<ContentType[]>([]);
    const [topicTitle, setTopicTitle] = useState('');
    const { forumID, topicID } = useParams();
    const { user } = useAuth();
    const [primaryComments, setPrimaryComments] = useState<CommentType[]>([]);

    const getPrimaryComments = () => {
        if (forumID && topicID) {
            axiosServices
                .post(`forum-management/forums/${forumID}/topics/${topicID}/comments/`)
                .then((res) => {
                    if (res.status === 200 && res.data.data) {
                        setPrimaryComments(res.data.data);
                    }
                })
                .catch((e: any) => {
                    console.log(e);
                });
        }
    };

    useEffect(() => {
        const init = async () => {
            if (user && user.acl) {
                if (checkSystemAcl('forum', ['read'], user.acl) && forumID) {
                    const haveConAccess = await checkForumMultipleAccess(parseInt(forumID, 10), 'topic-content', [
                        'read',
                        'create',
                        'update',
                        'delete'
                    ]);
                    if (haveConAccess && haveConAccess.read) {
                        setContentAccess(haveConAccess);
                        axiosServices
                            .post(`forum-management/forums/${forumID}/topics/${topicID}/contents/`)
                            .then((res) => {
                                if (res.status === 200 && res.data.data) {
                                    const contentList: ContentType[] = [];
                                    res.data.data.forEach((item: any, index: number) => {
                                        contentList.push({
                                            title: item.attributes.title,
                                            order: item.attributes.order,
                                            type: item.attributes.content_type?.name,
                                            text: item.attributes.text,
                                            url: item.attributes.url,
                                            picture: fullFilePath(item.attributes.picture),
                                            video: fullFilePath(item.attributes.video),
                                            audio: fullFilePath(item.attributes.audio)
                                        });
                                    });
                                    setContents(contentList);
                                    setLoading(false);
                                }
                            })
                            .catch((e: any) => {
                                console.log(e);
                            });
                    }
                }
            } else {
                setLoading(false);
            }
        };
        init();
    }, [forumID, topicID, user]);

    useEffect(() => {
        const init = async () => {
            if (forumID && topicID) {
                axiosServices
                    .post(`forum-management/forums/${forumID}/topics/${topicID}/details/`)
                    .then((res) => {
                        if (res.status === 200 && res.data.data) {
                            setTopicTitle(res.data.data.attributes?.name);
                            setBankSupport(res.data.data.attributes?.supports_question_bank);
                        }
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            }
            if (forumID) {
                const haveAccess = await checkForumMultipleAccess(parseInt(forumID, 10), 'primary-comment', [
                    'create',
                    'read',
                    'update',
                    'delete'
                ]);
                if (haveAccess) {
                    setCommentAccess(haveAccess);
                } else {
                    setCommentAccess({ read: false });
                }
            }
        };
        init();
    }, [forumID, topicID]);

    useEffect(() => {
        const init = async () => {
            if (forumID) {
                const haveBankAccess = await checkForumAccess(parseInt(forumID, 10), 'question-bank', ['create']);
                if (haveBankAccess) {
                    setBankAccess(true);
                }
                const havePCAccess = await checkForumMultipleAccess(parseInt(forumID, 10), 'primary-comment', [
                    'read',
                    'create',
                    'delete',
                    'update'
                ]);
                if (havePCAccess && havePCAccess.read) {
                    setDiscussionAccess(havePCAccess);
                    // Get Primary comments
                    axiosServices
                        .post(`forum-management/forums/${forumID}/topics/${topicID}/comments/`)
                        .then((res) => {
                            if (res.status === 200 && res.data.data) {
                                setPrimaryComments(res.data.data);
                                setLoading(false);
                            }
                        })
                        .catch((e: any) => {
                            console.log(e);
                        });
                } else {
                    setDiscussionAccess({ read: false });
                }
            }
        };
        init();
    }, [forumID, topicID]);

    useEffect(() => {
        const init = async () => {
            if (forumID && topicID) {
                const haveAccess = await checkForumAccess(parseInt(forumID, 10), 'topic', ['read']);
                if (haveAccess) {
                    axiosServices.post(`forum-management/forums/${forumID}/topics/${topicID}/view/`);
                }
            }
        };
        init();
    }, [forumID, topicID]);

    return (
        <>
            <Container sx={{ background: '#FFF', padding: 2, borderRadius: 5 }}>
                {loading && <DataLoading />}
                {!loading && !contentAccess.read && <AccessDenied />}
                {!loading && contentAccess.read && (
                    <>
                        <Typography variant="h2" color="initial" textAlign="center" marginY={5}>
                            {checkNullInfo(topicTitle)}
                        </Typography>
                        <Grid container>
                            <Grid item sm={6}>
                                <Typography variant="h3" color="initial">
                                    {langString('content')}
                                </Typography>
                            </Grid>
                            <Grid item sm={6}>
                                {contentAccess.update && contentAccess.create && contentAccess.delete && (
                                    <Button
                                        component={Link}
                                        to={`/manage-content/${forumID}/${topicID}`}
                                        variant="contained"
                                        sx={{ margin: 1 }}
                                    >
                                        {langString('managecontent')}
                                    </Button>
                                )}
                                {bankAccess && bankSupport && (
                                    <Button
                                        component={Link}
                                        to={`/question-bank-create/${forumID}/${topicID}`}
                                        variant="contained"
                                        sx={{ margin: 1 }}
                                    >
                                        {langString('selectcomments')}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>

                        <Content contents={contents} />

                        <Typography variant="h3" margin={1} color="initial">
                            {langString('discussion')}
                        </Typography>
                        {commentAccess && commentAccess.create && (
                            <Box>
                                <PostComment primary refreshDiscussion={() => getPrimaryComments()} />
                            </Box>
                        )}
                        {discussionAccess && forumID && (
                            <Discussion
                                refreshDiscussion={() => getPrimaryComments()}
                                forumID={parseInt(forumID, 10)}
                                primaryComments={primaryComments}
                            />
                        )}
                    </>
                )}
            </Container>
        </>
    );
}
