import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

type selectFieldValues = {
    value: string | number;
    name: string | number;
};

type SelectFieldProps = {
    name: string;
    labelName?: string | JSX.Element;
    menuItems: selectFieldValues[];
    isRequired?: boolean;
    formik?: any;
};

const SelectField = ({ name, labelName, menuItems, isRequired = false, formik }: SelectFieldProps) => (
    <>
        <FormControl fullWidth sx={{ marginY: 1 }}>
            <InputLabel id={`${name}-select`} sx={{ m: -0.5 }}>
                {labelName}
            </InputLabel>
            <Select
                required={isRequired}
                size="small"
                labelId={`${name}-select`}
                id={name}
                name={name}
                value={formik.values[name]}
                onChange={(e, value) => {
                    formik.setFieldValue(e.target.name, e.target.value);
                }}
                label={labelName}
                error={formik?.touched[name] && Boolean(formik?.errors[name])}
            >
                {menuItems?.map((item: selectFieldValues) => (
                    <MenuItem key={item?.value} value={item?.value}>
                        {!item.value ? <em>{item.name}</em> : item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    </>
);

export default SelectField;
