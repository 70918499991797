import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

type accordionFAQ = {
    id: number;
    summary: string;
    details: string;
};

export default function AccordionFAQ({ id, summary, details }: accordionFAQ) {
    return (
        <>
            <Accordion sx={{ marginY: '10px' }}>
                <AccordionSummary
                    sx={{ backgroundColor: 'blue' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id={`panel1a-header-${id}`}
                >
                    <Typography variant="h3" color="white">
                        {summary}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="subtitle1">{details}</Typography>
                </AccordionDetails>
            </Accordion>
        </>
    );
}
