import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import useAuth from 'hooks/useAuth';
import { checkForumMultipleAccess } from 'utils/Helpers';
import AccessDenied from 'components/common/AccessDenied';
import DataLoading from 'components/common/DataLoading';
import { AccessType } from 'types/UserDataTypes';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import ConfirmButton from 'components/common/ConfirmButton';
import ReOrder, { ReOrderItemType } from 'components/common/ReOrder';

export default function ManageContent() {
    const [loading, setLoading] = useState(true);
    const [access, setAccess] = useState<AccessType>({ read: false });
    const [title, setTitle] = useState('');
    const [rows, setRows] = useState<any[]>([]);
    const [reOrders, setReOrders] = useState<ReOrderItemType[]>([]);
    const { forumID, topicID } = useParams();
    const { user } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const contentDelete = (conID: number) => {
        if (access.delete && conID) {
            try {
                axiosServices.delete(`forum-management/forums/${forumID}/contents/${conID}/delete/`).then((res) => {
                    if (res.status === 200) {
                        const allItems: any[] = [...rows];
                        const updatedItems = allItems.filter((fm) => fm.id !== conID);
                        setRows(updatedItems);
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: 'Content Deleted',
                            variant: 'alert',
                            alertSeverity: 'success'
                        });
                    }
                });
            } catch (error) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: error,
                    variant: 'alert',
                    alertSeverity: 'success'
                });
            }
        }
    };

    const reorderList = (orderedItems: ReOrderItemType[]) => {
        const orderList = orderedItems.map(({ description, ...rest }) => rest);
        const postData = {
            data: {
                type: 'Content',
                attributes: {
                    objects: orderList
                }
            }
        };
        setLoading(true);
        axiosServices.post(`forum-management/forums/${forumID}/topics/${topicID}/contents/re-order/`, postData).then((res) => {
            if (res.status === 200 && res.data.data) {
                setRows(res.data.data);
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'Forum Contents Reordered',
                    variant: 'alert',
                    alertSeverity: 'success'
                });
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        const init = async () => {
            if (user && user.acl && forumID && topicID) {
                const haveAccess = await checkForumMultipleAccess(parseInt(forumID, 10), 'topic-content', [
                    'read',
                    'create',
                    'update',
                    'delete'
                ]);
                if (haveAccess && haveAccess.read) {
                    setAccess(haveAccess);
                    axiosServices.post(`forum-management/forums/${forumID}/topics/${topicID}/contents/`).then((res) => {
                        if (res.status === 200 && res.data.data) {
                            setRows(res.data.data);
                            setLoading(false);
                            const orderItemList: ReOrderItemType[] = [];
                            res.data.data.forEach((item: any) =>
                                orderItemList.push({
                                    id: item.id,
                                    description: item.attributes.title,
                                    order: item.attributes.order
                                })
                            );
                            setReOrders(orderItemList);
                        }
                    });
                } else {
                    navigate(`/forum-topics/${forumID}`, { replace: true });
                }
            }
            setLoading(false);
        };
        init();
    }, [forumID, user, topicID, navigate]);

    useEffect(() => {
        const init = async () => {
            if (forumID && topicID) {
                axiosServices.post(`forum-management/forums/${forumID}/topics/${topicID}/details/`).then((res) => {
                    if (res.status === 200 && res.data.data) {
                        setTitle(res.data.data.attributes?.name);
                    }
                });
            } else {
                navigate(`/forum-topics/${forumID}`, { replace: true });
            }
        };
        init();
    }, [forumID, topicID, navigate]);

    return (
        <>
            {loading && <DataLoading />}
            {!loading && !access.read && <AccessDenied />}
            {!loading && access.read && (
                <Box>
                    <Grid container justifyContent="center">
                        <Grid item xs={8} sm={12}>
                            <Typography variant="h2" color="initial" textAlign="center" marginY="20px">
                                {title} <strong>{langString('contents')}</strong>
                            </Typography>
                            {access.create && (
                                <Button component={Link} to={`/add-content/${forumID}/${topicID}`} variant="contained" sx={{ margin: 1 }}>
                                    {langString('addcontent')}
                                </Button>
                            )}
                            {access.update && <ReOrder items={reOrders} reorderList={(items: ReOrderItemType[]) => reorderList(items)} />}
                        </Grid>
                    </Grid>
                    <Grid container xs={12} spacing={3} justifyItems="center" alignItems="center" marginTop="20px">
                        <Grid item sm={12} xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                {langString('title')}
                                            </TableCell>
                                            <TableCell align="center" component="th" scope="row">
                                                {langString('action')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.length ? (
                                            rows.map((row) => (
                                                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell>{row.attributes?.title}</TableCell>
                                                    <TableCell align="center">
                                                        {access.update && (
                                                            <Button
                                                                size="small"
                                                                component={Link}
                                                                to={`/edit-content/${forumID}/${row.id}`}
                                                                variant="contained"
                                                                sx={{ margin: 1 }}
                                                                color="success"
                                                            >
                                                                {langString('edit')}
                                                            </Button>
                                                        )}
                                                        {access.delete && (
                                                            <ConfirmButton
                                                                buttonColor="error"
                                                                subTitle="Delete this content!"
                                                                confirmed={() => contentDelete(row.id)}
                                                            />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <Typography variant="h4" color="initial" textAlign="center">
                                                {langString('datanotavailable')}
                                            </Typography>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
}
