import { Button, Container, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import langString from 'utils/langString';
import { useParams } from 'react-router-dom';
import axiosServices from 'utils/axiosServices';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';

type PostCommentProps = {
    primary: boolean;
    commentID?: number;
    repliedTO?: number;
    refreshDiscussion?: () => void;
    refreshReplies?: () => void;
};
export default function PostComment({ refreshDiscussion, refreshReplies, primary, commentID, repliedTO }: PostCommentProps) {
    const [comment, setComment] = useState<string>('');
    const { forumID, topicID } = useParams();
    const dispatch = useDispatch();

    // post comment onSubmit
    const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (comment) {
            let reqUrl = `forum-management/forums/${forumID}/topics/${topicID}/comments/create/`;
            const attributes: any = {
                comment
            };
            if (!primary && commentID && repliedTO) {
                reqUrl = `forum-management/forums/${forumID}/comments/${commentID}/replies/create/`;
                attributes.replied_to_id = commentID;
            }
            axiosServices
                .post(reqUrl, {
                    data: {
                        type: 'Comment',
                        attributes
                    }
                })
                .then((res) => {
                    if (res.status === 201) {
                        setComment('');
                        if (primary && refreshDiscussion) {
                            refreshDiscussion();
                        }
                        if (!primary && refreshReplies) {
                            refreshReplies();
                        }
                        dispatch({
                            type: SNACKBAR_OPEN,
                            open: true,
                            message: 'Comment Posted',
                            variant: 'alert',
                            alertSeverity: 'success'
                        });
                    }
                })
                .catch((e: any) => {
                    console.log(e);
                });
        }
    };

    return (
        <>
            <Container maxWidth="lg" sx={{ marginTop: 2 }}>
                <form onSubmit={handleOnSubmit}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={10}>
                            <TextField
                                id="outlined-textarea"
                                name="comment"
                                value={comment}
                                placeholder="What’s on your mind ?"
                                rows={2}
                                fullWidth
                                multiline
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Grid container justifyContent="flex-end" spacing={1}>
                                <Grid item>
                                    <Button variant="contained" type="submit" color="primary">
                                        {langString('post')} {primary ? langString('comment') : langString('reply')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Container>
        </>
    );
}
