import { Container, Typography } from '@mui/material';
import langString from 'utils/langString';
import Comment, { CommentType } from '../Comment';

type DiscussionPropsType = {
    forumID: number;
    primaryComments: CommentType[];
    refreshDiscussion: () => void;
};
export default function Discussion({ forumID, primaryComments, refreshDiscussion }: DiscussionPropsType) {
    return (
        <Container>
            <>
                {primaryComments && primaryComments.length ? (
                    primaryComments.map(
                        (item: CommentType) =>
                            item.attributes?.created_by && (
                                <Comment refreshDiscussion={refreshDiscussion} forumID={forumID} primary com={item} />
                            )
                    )
                ) : (
                    <Typography variant="h4" color="initial">
                        {langString('nodiscussionavailable')}
                    </Typography>
                )}
            </>
        </Container>
    );
}
