import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
    blueBar: {
        backgroundColor: '#273c75',
        padding: 25,
        width: '100%',
        color: '#fff'
    },
    orgName: {
        fontSize: 25,
        marginLeft: 100,
        cursor: 'pointer',
        display: 'inline'
    },
    projectName: {
        fontSize: 22,
        float: 'right',
        marginRight: 100,
        cursor: 'pointer',
        display: 'inline'
    }
});

export default function MinimalHeader() {
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <div className={classes.blueBar}>
            <Typography className={classes.orgName} onClick={() => navigate('/', { replace: true })}>
                USAID/RI
            </Typography>
            <Typography className={classes.projectName} onClick={() => navigate('/', { replace: true })}>
                Community of Practice
            </Typography>
        </div>
    );
}
