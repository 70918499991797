import { Grid, Typography } from '@mui/material';
import langString from 'utils/langString';

export default function HeaderDataTable() {
    return (
        <>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <Typography variant="h4" color="initial" noWrap>
                    {langString('scheduled')} {langString('date')}
                </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <Typography variant="h4" color="initial" noWrap>
                    {langString('time')}
                </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <Typography variant="h4" color="initial" noWrap>
                    {langString('company')}
                </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <Typography variant="h4" color="initial" noWrap>
                    {`${langString('industry')}-${langString('official')}`}
                </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Typography variant="h4" color="initial">
                    {`${langString('action')}`}
                </Typography>
            </Grid>
        </>
    );
}
