import { Box, CircularProgress } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import axiosServices from 'utils/axiosServices';
import CustomizedTables from './CustomizedTables';

export type UserAuthorities = {
    id: string;
    name: string;
};

type UserAuthoritiesResponse = {
    data: {
        type: string;
        id: string;
        attributes: {
            name: string;
            description: string;
            created_at: string;
            updated_at: string;
        };
    }[];
};

export default function Acl() {
    const [userAuthorities, setUserAuthorities] = useState<UserAuthorities[]>([]);
    useEffect(() => {
        const getUserAuthorities = async () => {
            const usersRoles: AxiosResponse<UserAuthoritiesResponse> = await axiosServices.post('/users/permissions/user-authorities/');
            const authorities = usersRoles.data.data.map((item) => ({ id: item.id, name: item.attributes.name }));
            setUserAuthorities(authorities);
        };
        getUserAuthorities();
    }, []);

    return (
        <>
            {userAuthorities.length > 0 ? (
                <CustomizedTables userAuthorities={userAuthorities} />
            ) : (
                <Box textAlign="center">
                    <CircularProgress />
                </Box>
            )}
        </>
    );
}
