import { Grid, Typography, Button } from '@mui/material';
import axiosServices from 'utils/axiosServices';
import langString from 'utils/langString';
import { checkForumAccess } from 'utils/Helpers';
import { useEffect, useState } from 'react';

type ActionPropsType = {
    primary: boolean;
    open: boolean;
    setOpen: (value: boolean) => void;
    liked: boolean;
    comID: number;
    forumID: number;
    totalReplies: number;
    refreshDiscussion?: () => void;
    refreshReplies?: () => void;
};
export default function Action({
    primary,
    open,
    liked,
    comID,
    forumID,
    totalReplies,
    refreshDiscussion,
    refreshReplies,
    setOpen
}: ActionPropsType) {
    const [likeAccess, setLikeAccess] = useState(false);
    const [replyAccess, setReplyAccess] = useState(false);

    const likeToggle = async () => {
        if (forumID && comID) {
            if (likeAccess) {
                axiosServices
                    .post(`forum-management/forums/${forumID}/comments/${comID}/like/`)
                    .then((res) => {
                        if (res.status === 200) {
                            if (refreshReplies) {
                                refreshReplies();
                            }
                            if (refreshDiscussion) {
                                refreshDiscussion();
                            }
                        }
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            }
        }
    };

    useEffect(() => {
        const init = async () => {
            const haveAccess = await checkForumAccess(forumID, 'like', ['create']);
            if (haveAccess) {
                setLikeAccess(true);
            }
            const haveReplyAccess = await checkForumAccess(forumID, 'reply-comment', ['create']);
            if (haveReplyAccess) {
                setReplyAccess(true);
            }
        };
        init();
    }, [forumID]);

    return (
        <Grid container xs={12} justifyContent={{ xs: 'center', sm: 'flex-end' }} alignItems="center">
            {likeAccess && (
                <Grid item xs={3} sm={1}>
                    <Typography component={Button} onClick={() => likeToggle()} variant="subtitle2" color="blue" fontSize={16}>
                        {liked ? langString('unlike') : langString('like')}
                    </Typography>
                </Grid>
            )}
            {primary && (
                <>
                    {totalReplies ? (
                        <Grid item xs={6} sm={2}>
                            <Typography component="span" borderRight="1px solid blue" />
                            <Typography component={Button} onClick={() => setOpen(!open)} variant="subtitle2" color="blue" fontSize={16}>
                                {open ? langString('closethread') : langString('viewthread')}
                            </Typography>
                            <Typography component="span" borderRight="1px solid blue" />
                        </Grid>
                    ) : (
                        <Grid item>
                            <Typography component="span" borderRight="1px solid blue" />
                        </Grid>
                    )}
                    {replyAccess && (
                        <Grid item xs={3} sm={1}>
                            <Typography component={Button} onClick={() => setOpen(!open)} variant="subtitle2" color="blue" fontSize={16}>
                                {langString('reply')}
                            </Typography>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
}
