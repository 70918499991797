import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

export function dateTimeDuration(date: string) {
    const dateTime = dayjs(date);
    return `Hour: ${dateTime.format('h')}  Minute: ${dateTime.minute()} ${dateTime.format('A')}`;
}
export function isPrevDate(date: string) {
    dayjs.extend(relativeTime);
    return dayjs().isBefore(dayjs(date));
}
