import { Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import AccordionFAQ from './AccordionFAQ';
import { useParams } from 'react-router-dom';
import axiosServices from 'utils/axiosServices';
import useAuth from 'hooks/useAuth';
import { checkForumAccess, checkSystemAcl } from 'utils/Helpers';
// import AccessDenied from 'components/common/AccessDenied';
import DataLoading from 'components/common/DataLoading';
import langString from 'utils/langString';

export default function Faq() {
    const [loading, setLoading] = useState(true);
    const [access, setAccess] = useState(true);
    const [faqs, setFaqs] = useState<any[]>([]);
    const [forumName, setForumName] = useState('');
    const { forumID } = useParams();
    const { user } = useAuth();
    // server request
    useEffect(() => {
        const init = async () => {
            try {
                if (user && user.acl) {
                    if (checkSystemAcl('forum', ['read'], user.acl) && forumID) {
                        const haveAccess = await checkForumAccess(parseInt(forumID, 10), 'faq', ['read']);
                        if (haveAccess) {
                            axiosServices
                                .post(`forum-management/forums/${forumID}/faqs/`)
                                .then((res) => {
                                    if (res.status === 200 && res.data.data) {
                                        setForumName(res.data.data[0].attributes.forum.name);
                                        setFaqs(res.data.data);
                                    }
                                })
                                .catch((e: any) => {
                                    console.log(e);
                                });
                            setLoading(false);
                            setAccess(true);
                        } else {
                            setLoading(false);
                            setAccess(false);
                        }
                    } else {
                        setLoading(false);
                        setAccess(false);
                    }
                } else {
                    setLoading(false);
                    setAccess(false);
                }
            } catch (error) {
                console.log(error);
                setLoading(false);
                setAccess(false);
            }
        };
        init();
    }, [forumID, user]);

    return (
        <>
            {loading && <DataLoading />}
            {/* {!loading && !access && <AccessDenied />} */}
            {!loading && access && (
                <>
                    <Container maxWidth="sm" sx={{ background: '#FFF', borderRadius: 5, padding: 4 }}>
                        <Typography variant="h2" color="initial" textAlign="center">
                            {forumName || langString('datanotavailable')}
                        </Typography>
                        {faqs && faqs.length ? (
                            faqs.map((item: any, index: number) => (
                                <AccordionFAQ
                                    key={index}
                                    id={item.id}
                                    summary={item.attributes.question}
                                    details={item.attributes.answer}
                                />
                            ))
                        ) : (
                            <Typography variant="h4" color="initial" textAlign="center">
                                {langString('datanotavailable')}
                            </Typography>
                        )}
                    </Container>
                </>
            )}
        </>
    );
}
