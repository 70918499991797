// material-ui
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { IconButton, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { UserAuthorities } from '.';

// styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

type CustomizedTablesT = {
    userAuthorities: UserAuthorities[];
};

// ==============================|| TABLE - CUSTOMIZED ||============================== //

export default function CustomizedTables({ userAuthorities }: CustomizedTablesT) {
    return (
        <MainCard content={false}>
            <TableContainer>
                <Table sx={{ minWidth: 320 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ pl: 3 }}>Name</StyledTableCell>
                            <StyledTableCell sx={{ pr: 3 }} align="right">
                                Actions
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userAuthorities.map((row) => (
                            <StyledTableRow hover key={row.id}>
                                <StyledTableCell sx={{ pl: 3, textTransform: 'capitalize' }} component="th" scope="row">
                                    {row.name.split('-').join(' ')}
                                </StyledTableCell>
                                <StyledTableCell sx={{ pr: 3 }} align="right">
                                    <IconButton color="secondary" size="large" component={Link} to={`/super-user/acl/edit/${row.id}`}>
                                        <Tooltip title="Edit" placement="top">
                                            <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                        </Tooltip>
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </MainCard>
    );
}
