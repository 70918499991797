/* eslint-disable jsx-a11y/media-has-caption */
import { Box, TextareaAutosize, FormControl, InputLabel, TextField, Typography } from '@mui/material';
import langString from 'utils/langString';
import FileInput from 'components/common/FileInput';

type MediaFormPropsType = {
    contentType: number;
    content: string;
    setContent: (value: string) => void;
};
export default function MediaForm({ content, contentType, setContent }: MediaFormPropsType) {
    return (
        <>
            {contentType === 1 && (
                <Box sx={{ padding: 1, border: '1px solid #DDD' }}>
                    <TextareaAutosize
                        value={content || ''}
                        onChange={(event: any) => setContent(event.target.value)}
                        aria-label="content"
                        minRows={6}
                        placeholder={langString('content')}
                        style={{ width: '100%' }}
                    />
                </Box>
            )}
            {contentType === 2 && (
                <>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <FileInput fileType="audio" setFile={(value: any) => setContent(value)} />
                        <InputLabel sx={{ marginTop: 3 }} htmlFor="audio">
                            <Typography variant="h5">{langString('uploadaudiofile')}:</Typography>
                        </InputLabel>
                    </FormControl>
                    {content && (
                        <Box sx={{ padding: 1, border: '1px solid #DDD', margin: 1 }}>
                            <audio controls>
                                <source src={content} type="audio/ogg" />
                                <source src={content} type="audio/mpeg" />
                            </audio>
                        </Box>
                    )}
                </>
            )}
            {contentType === 3 && (
                <>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <FileInput fileType="video" setFile={(value: any) => setContent(value)} />
                        <InputLabel sx={{ marginTop: 3 }} htmlFor="video">
                            <Typography variant="h5">{langString('uploadvideofile')}:</Typography>
                        </InputLabel>
                    </FormControl>
                    {content && (
                        <Box sx={{ padding: 1, border: '1px solid #DDD', margin: 1 }}>
                            <video width="100%" controls>
                                <source src={content} type="video/mp4" />
                                <source src={content} type="video/ogg" />
                            </video>
                        </Box>
                    )}
                </>
            )}
            {contentType === 4 && (
                <>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <FileInput fileType="image" setFile={(value: any) => setContent(value)} />
                        <InputLabel sx={{ marginTop: 3 }} htmlFor="image">
                            <Typography variant="h5">{langString('uploadimagefile')}:</Typography>
                        </InputLabel>
                    </FormControl>
                    {content && (
                        <Box sx={{ padding: 1, border: '1px solid #DDD', margin: 1 }}>
                            <img width="100%" src={content} alt="contentImage" />
                        </Box>
                    )}
                </>
            )}
            {contentType === 5 && (
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <TextField
                        sx={{ marginY: 1 }}
                        required
                        size="small"
                        fullWidth
                        id="link"
                        name="link"
                        value={content}
                        label={langString('link')}
                        onChange={(event: any) => setContent(event.target.value)}
                    />
                </FormControl>
            )}
        </>
    );
}
