import { useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import DataLoading from 'components/common/DataLoading';
import { useParams } from 'react-router-dom';
import axiosServices from 'utils/axiosServices';
import { checkNullInfo, fullFilePath } from 'utils/Helpers';
import langString from 'utils/langString';
import Content, { ContentType } from 'views/contentAndDiscussion/content';

export default function GuestContent() {
    const [loading, setLoading] = useState(true);
    const [contents, setContents] = useState<ContentType[]>([]);
    const [topicTitle, setTopicTitle] = useState('');
    const { forumID, topicID } = useParams();

    useEffect(() => {
        const init = async () => {
            if (forumID) {
                axiosServices
                    .post(`forum-management/forums/${forumID}/topics/${topicID}/contents/`)
                    .then((res) => {
                        if (res.status === 200 && res.data.data) {
                            const contentList: ContentType[] = [];
                            res.data.data.forEach((item: any, index: number) => {
                                contentList.push({
                                    title: item.attributes.title,
                                    order: item.attributes.order,
                                    type: item.attributes.content_type?.name,
                                    text: item.attributes.text,
                                    url: item.attributes.url,
                                    picture: fullFilePath(item.attributes.picture),
                                    video: fullFilePath(item.attributes.video),
                                    audio: fullFilePath(item.attributes.audio)
                                });
                            });
                            setContents(contentList);
                            setLoading(false);
                        }
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            } else {
                setLoading(false);
            }
        };
        init();
    }, [forumID, topicID]);

    useEffect(() => {
        const init = async () => {
            if (forumID && topicID) {
                axiosServices
                    .post(`forum-management/forums/${forumID}/topics/${topicID}/details/`)
                    .then((res) => {
                        if (res.status === 200 && res.data.data) {
                            setTopicTitle(res.data.data.attributes?.name);
                        }
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            }
        };
        init();
    }, [forumID, topicID]);

    return (
        <>
            <Container sx={{ background: '#FFF', padding: 2, borderRadius: 5 }}>
                {loading && <DataLoading />}
                {!loading && (
                    <>
                        <Typography variant="h2" color="initial" textAlign="center" marginY={5}>
                            {checkNullInfo(topicTitle)}
                        </Typography>
                        <Grid container>
                            <Grid item sm={6}>
                                <Typography variant="h3" color="initial">
                                    {langString('content')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Content contents={contents} />
                    </>
                )}
            </Container>
        </>
    );
}
