import { Grid, Icon } from '@mui/material';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

type CounterPropsType = {
    likes: number;
    replies: number;
    primary: boolean;
};

export default function Counter({ likes, replies, primary }: CounterPropsType) {
    return (
        <Grid container xs={6} sm={1} textAlign="center">
            <Grid container xs={6} sm={12} textAlign="center">
                <Grid item xs={12}>
                    <Icon fontSize="large" component={ThumbUpIcon} />
                </Grid>
                <Grid item xs={12} marginBottom={0}>
                    {likes}
                </Grid>
            </Grid>
            {primary && (
                <Grid container xs={6} sm={12} textAlign="center">
                    <Grid item xs={12}>
                        <Icon fontSize="large" component={ModeCommentIcon} />
                    </Grid>
                    <Grid item xs={12}>
                        {replies}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}
