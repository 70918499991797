import { Container, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';

export default function SuperUser() {
    return (
        <Container maxWidth="xl">
            <Typography variant="h1" color="initial" marginY="15px" align="center">
                Super User
            </Typography>
            <Outlet />
        </Container>
    );
}
