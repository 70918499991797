import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Grid, Icon, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import langString from 'utils/langString';

export type rowsDataTable = {
    id?: number;
    scheduledDate: string;
    time: string;
    company: string;
    industryOfficial: string;
    action: string;
    deleteHandle?: (id: string) => void;
    accessDelete?: boolean;
};

export default function RowsDataTable({
    id,
    scheduledDate,
    time,
    company,
    industryOfficial,
    action,
    deleteHandle,
    accessDelete
}: rowsDataTable) {
    return (
        <>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <Typography variant="h4" color="initial" noWrap sx={{ backgroundColor: '#eeeeee', padding: '15px' }}>
                    {scheduledDate}
                </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <Typography variant="h4" color="initial" noWrap sx={{ backgroundColor: '#eeeeee', padding: '15px' }}>
                    {time}
                </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <Typography variant="h4" color="initial" noWrap sx={{ backgroundColor: '#eeeeee', padding: '15px' }}>
                    {company}
                </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <Typography variant="h4" color="initial" noWrap sx={{ backgroundColor: '#eeeeee', padding: '15px' }}>
                    {industryOfficial}
                </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <Button fullWidth component={Link} to={action} variant="contained" sx={{ paddingY: '13px', borderRadius: '0px' }}>
                    {langString('views')}
                </Button>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                {accessDelete && (
                    <Button onClick={() => deleteHandle?.(`${id}`)}>
                        <Icon sx={{ fontSize: ['40px', '42px'], color: 'red' }} component={DeleteIcon} />
                    </Button>
                )}
            </Grid>
        </>
    );
}
