import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import ForumsTable from 'components/Table/ForumsTable';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import MainCard from 'ui-component/cards/MainCard';
import axiosServices from 'utils/axiosServices';
import { checkForumMultipleAccess } from 'utils/Helpers';
import langString from 'utils/langString';
import HeaderDataTable from './HeaderDataTable';
import MobileCard from './MobileCard';
import RowsDataTable, { rowsDataTable } from './RowsDataTable';

type LiveSessionsRes = {
    data: {
        type: string;
        id: string;
        attributes: {
            forum: {
                id: number;
                name: string;
            };
            start_datetime: string;
            company: {
                id: number;
                name: string;
            };
            representatives: {
                id: number;
                name: string;
            }[];
            status: 'Upcoming' | 'Completed';
        };
    }[];
};

type RowsT = {
    completedLiveSessionRows: rowsDataTable[];
    upcomingLiveSessionsRows: rowsDataTable[];
};

const LiveSessionList = () => {
    const [rows, setRows] = useState<RowsT>({ completedLiveSessionRows: [], upcomingLiveSessionsRows: [] });
    const [access, setAccess] = useState({ create: false, read: false, delete: false });

    const { forumID } = useParams();

    // server request
    useEffect(() => {
        const getSessions = async () => {
            if (typeof forumID !== 'undefined') {
                const accessUser = await checkForumMultipleAccess(parseInt(forumID, 10), 'live-session', ['create', 'read', 'delete']);
                setAccess(accessUser);
                const liveSessionsRes: AxiosResponse<LiveSessionsRes> = await axiosServices.post(
                    `forum-management/forums/${forumID}/sessions/`
                );
                const liveSessionsCompleted: rowsDataTable[] = [];
                const liveSessionsUpcoming: rowsDataTable[] = [];
                liveSessionsRes.data.data.forEach((liveSession) => {
                    if (liveSession.attributes.status === 'Completed') {
                        liveSessionsCompleted.push({
                            id: parseInt(liveSession?.id, 10),
                            scheduledDate: dayjs(liveSession.attributes.start_datetime).format('YYYY-MM-DD'),
                            time: dayjs(liveSession.attributes.start_datetime).format('hh:mm:A'),
                            company: liveSession.attributes.company.name,
                            industryOfficial: liveSession.attributes.representatives.map((val) => val.name).join(', '),
                            action: liveSession?.id
                        });
                    } else {
                        liveSessionsUpcoming.push({
                            id: parseInt(liveSession?.id, 10),
                            scheduledDate: dayjs(liveSession.attributes.start_datetime).format('YYYY-MM-DD'),
                            time: dayjs(liveSession.attributes.start_datetime).format('hh:mm:A'),
                            company: liveSession.attributes.company.name,
                            industryOfficial: liveSession.attributes.representatives.map((val) => val.name).join(', '),
                            action: liveSession?.id
                        });
                    }
                });
                setRows((prevState) => ({
                    ...prevState,
                    completedLiveSessionRows: liveSessionsCompleted,
                    upcomingLiveSessionsRows: liveSessionsUpcoming
                }));
            }
        };
        getSessions();
    }, [forumID]);

    const deleteHandle = async (id: string) => {
        const cloneRow = _.cloneDeep(rows);
        const liveSessionsRes: AxiosResponse<any> = await axiosServices.delete(`forum-management/forums/${forumID}/sessions/${id}/delete/`);
        if (liveSessionsRes.status === 200) {
            const liveSessionsId = liveSessionsRes.data.data.id;
            const completedLiveSessionRowIndx = cloneRow.completedLiveSessionRows.findIndex(
                (row) => row?.id! === parseInt(liveSessionsId, 10)
            );
            const upcomingLiveSessionsRowIndx = cloneRow.upcomingLiveSessionsRows.findIndex(
                (row) => row?.id! === parseInt(liveSessionsId, 10)
            );
            if (cloneRow.completedLiveSessionRows[completedLiveSessionRowIndx]) {
                cloneRow.completedLiveSessionRows.splice(completedLiveSessionRowIndx, 1);
            }
            if (cloneRow.upcomingLiveSessionsRows[upcomingLiveSessionsRowIndx]) {
                cloneRow.upcomingLiveSessionsRows.splice(upcomingLiveSessionsRowIndx, 1);
            }

            setRows(cloneRow);
        }
        console.log(rows.completedLiveSessionRows);
    };
    const completedLiveSessionData = rows.completedLiveSessionRows.map((row) => (
        <RowsDataTable
            key={row.id}
            id={row.id}
            scheduledDate={row.scheduledDate}
            time={row.time}
            company={row.company}
            action={`/forum-topics/${forumID}/live-session/${row.id}`}
            industryOfficial={row.industryOfficial}
            deleteHandle={deleteHandle}
            accessDelete={access.delete}
        />
    ));

    const upcomingLiveSessionsData = rows.upcomingLiveSessionsRows.map((row) => (
        <RowsDataTable
            key={row.id}
            id={row.id}
            scheduledDate={row.scheduledDate}
            time={row.time}
            company={row.company}
            action={`/forum-topics/${forumID}/live-session/${row.id}`}
            industryOfficial={row.industryOfficial}
            deleteHandle={deleteHandle}
            accessDelete={access.delete}
        />
    ));

    const completedLiveSessionRowsMobile = rows.completedLiveSessionRows.map((row) => (
        <MobileCard
            key={row.id}
            id={row.id!}
            scheduledDate={row.scheduledDate}
            time={row.time}
            company={row.company}
            action={`/forum-topics/${forumID}/live-session/${row.id}`}
            industryOfficial={row.industryOfficial}
            deleteHandle={deleteHandle}
            accessDelete={access.delete}
        />
    ));
    const upcomingLiveSessionsRowsMobile = rows.upcomingLiveSessionsRows.map((row) => (
        <MobileCard
            key={row.id}
            id={row.id}
            scheduledDate={row.scheduledDate}
            time={row.time}
            company={row.company}
            action={`/forum-topics/${forumID}/live-session/${row.id}`}
            industryOfficial={row.industryOfficial}
            deleteHandle={deleteHandle}
            accessDelete={access.delete}
        />
    ));

    return (
        <>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <MainCard sx={{ height: '100vh' }}>
                    <ForumsTable
                        title={`${langString('live')} ${langString('session')}`}
                        button={
                            <>
                                {access.create && (
                                    <Button
                                        component={Link}
                                        to={`/forum-topics/${forumID}/add-live-session`}
                                        variant="contained"
                                        sx={{ paddingY: '13px', borderRadius: '0px' }}
                                    >
                                        {`${langString('create')} ${langString('new')} ${langString('session')}`}
                                    </Button>
                                )}
                            </>
                        }
                    >
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h3" color="initial">
                                    {langString('upcoming')}
                                </Typography>
                            </Grid>
                            <HeaderDataTable />
                            {upcomingLiveSessionsData || (
                                <Typography variant="h3" color="red" margin={3}>
                                    {langString('emptydata')}
                                </Typography>
                            )}
                            <Grid item xs={12}>
                                <Typography variant="h3" color="initial">
                                    {langString('completed')}
                                </Typography>
                            </Grid>
                            {completedLiveSessionData || (
                                <Typography variant="h3" color="red" margin={3}>
                                    {langString('emptydata')}
                                </Typography>
                            )}
                        </>
                    </ForumsTable>
                </MainCard>
            </Box>
            {/*  For mobile */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Container maxWidth="sm">
                    <Typography variant="h2" color="initial" textAlign="center" marginY="20px">
                        {`${langString('live')} ${langString('session')}`}
                    </Typography>
                    <Button
                        component={Link}
                        to="/add-live-session"
                        variant="contained"
                        sx={{ display: 'block', textAlign: 'center', paddingY: '13px', borderRadius: '0px' }}
                    >
                        {`${langString('create')} ${langString('new')} ${langString('session')}`}
                    </Button>
                </Container>
                {completedLiveSessionRowsMobile || (
                    <Typography variant="h3" color="red" margin={3}>
                        {langString('emptydata')}
                    </Typography>
                )}
                {upcomingLiveSessionsRowsMobile || (
                    <Typography variant="h3" color="red" margin={3}>
                        {langString('emptydata')}
                    </Typography>
                )}
            </Box>
        </>
    );
};
export default LiveSessionList;
