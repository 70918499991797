import { Box, CircularProgress } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosServices from 'utils/axiosServices';
import CustomizedTables from './CustomizedTables';

// type
type AttributesT = {
    id: number;
    name: string;
    description: string;
    created_at: string;
    updated_at: string;
};

export type UsersRolesResT = {
    data: {
        type: string;
        id: number;
        attributes: {
            authority: AttributesT;
            map: {
                id: number;
                forum_module: {
                    id: number;
                    forum: {
                        id: number;
                        forum: {
                            type: string;
                            id: string;
                        };
                    };
                    module: AttributesT;
                };
                point: AttributesT;
            };
            access: boolean;
            updated_at: string;
        };
    }[];
};

type AccessPointesResT = {
    data: {
        type: string;
        id: number;
        attributes: AttributesT;
    }[];
};
type ForumModuleResT = {
    data: {
        type: string;
        id: number;
        attributes: AttributesT;
    }[];
};

// state type
export type UsersRolesT = {
    id: number;
    access: boolean;
    authority: string;
    module: {
        id: number;
        name: string;
    };
    point: {
        id: number;
        name: string;
    };
};

export type AccessiblePointsT = {
    id: number;
    name: string;
    checked: boolean;
};
export type ForumModulesT = {
    id: number;
    name: string;
    checked: boolean;
};

export default function ForumAclEdit() {
    const [usersRoles, setUsersRoles] = useState<UsersRolesT[]>([]);
    const [accessiblePoints, setAccessiblePoints] = useState<AccessiblePointsT[]>([]);
    const [forumModules, setForumModules] = useState<ForumModulesT[]>([]);

    const { forumID, authorityId } = useParams();

    useEffect(() => {
        const getUserAccess = async () => {
            const usersRolesRes: AxiosResponse<UsersRolesResT> = await axiosServices.post(
                `/users/permissions/forum-acl/${forumID}/authorities/${authorityId}/`
            );

            const usersRolesMapData = usersRolesRes.data.data.map((usersRole) => ({
                id: usersRole.id,
                access: usersRole.attributes.access,
                authority: usersRole.attributes.authority.name,
                module: { id: usersRole.attributes.map.forum_module.module.id, name: usersRole.attributes.map.forum_module.module.name },
                point: {
                    id: usersRole.attributes.map.point.id,
                    name: usersRole.attributes.map.point.name
                }
            }));

            setUsersRoles(usersRolesMapData);
        };

        const getAccessiblePoints = async () => {
            const accessiblePointsRes: AxiosResponse<AccessPointesResT> = await axiosServices.post(`/users/permissions/accessible-points/`);

            const accessiblePointsMapData = accessiblePointsRes.data.data.map((accessiblePoint) => ({
                id: accessiblePoint.id,
                name: accessiblePoint.attributes.name,
                checked: false
            }));
            setAccessiblePoints(accessiblePointsMapData);
        };
        const getForumModule = async () => {
            const forumModulesRes: AxiosResponse<ForumModuleResT> = await axiosServices.post(`/users/permissions/forum-modules/`);
            const forumModulesMapData = forumModulesRes.data.data.map((accessiblePoint) => ({
                id: accessiblePoint.id,
                name: accessiblePoint.attributes.name,
                checked: false
            }));
            setForumModules(forumModulesMapData);
        };
        getUserAccess();
        getAccessiblePoints();
        getForumModule();
    }, [forumID, authorityId]);

    return (
        <>
            {/* <Typography variant="body1" color="initial">
                {authorityId}
            </Typography> */}
            {usersRoles.length > 0 && accessiblePoints.length > 0 && forumModules.length > 0 ? (
                <CustomizedTables
                    forumID={forumID!}
                    authorityId={authorityId!}
                    usersRoles={usersRoles}
                    accessiblePoints={accessiblePoints}
                    forumModules={forumModules}
                    setUsersRoles={setUsersRoles}
                    setForumModules={setForumModules}
                    setAccessiblePoints={setAccessiblePoints}
                />
            ) : (
                <Box textAlign="center">
                    <CircularProgress />
                </Box>
            )}
        </>
    );
}
