import { Box, CardMedia, Typography } from '@mui/material';
import langString from 'utils/langString';

type media = {
    title: string;
    order: number;
    type: 'video' | 'audio' | 'text' | 'link' | 'image';
    text: string;
    url: string;
    picture: string;
    video: string;
    audio: string;
};
export default function Media({ type, text, url, title, order, picture, video, audio }: media) {
    if (type) {
        switch (type.toLowerCase()) {
            case 'video':
                return (
                    <>
                        <Box marginY="10px">
                            <Typography variant="h3" color="#616161" marginY={1}>
                                {`${order}. (${type}) ${title}`}
                            </Typography>
                            <Box marginX={{ xs: '0px', sm: '30px' }}>
                                <CardMedia height={256} component="video" controls src={video} />
                                <Typography variant="subtitle2" color="gray" marginY={1} fontSize={16}>
                                    {text}
                                </Typography>
                            </Box>
                        </Box>
                    </>
                );
            case 'audio':
                return (
                    <>
                        <Box marginY="10px">
                            <Typography variant="h3" color="#616161" marginY={1}>
                                {`${order}. (${type}) ${title}`}
                            </Typography>
                            <Box marginX={{ xs: '0px', sm: '30px' }}>
                                <CardMedia component="audio" controls src={audio} />
                                <Typography variant="subtitle2" color="gray" marginY={1} fontSize={16}>
                                    {text}
                                </Typography>
                            </Box>
                        </Box>
                    </>
                );
            case 'text':
                return (
                    <>
                        <Box marginY="10px">
                            <Typography variant="h3" color="#616161" marginY={1}>
                                {`${order}. (${type}) ${title}`}
                            </Typography>
                            <Box marginX={{ xs: '0px', sm: '30px' }}>
                                <Typography variant="subtitle2" color="gray" marginY={1} fontSize={16}>
                                    {text}
                                </Typography>
                            </Box>
                        </Box>
                    </>
                );
            case 'link':
                return (
                    <>
                        <Box marginY="10px">
                            <Typography variant="h3" color="#616161" marginY={1}>
                                {`${order}. (${type}) ${title}`}
                            </Typography>
                            <Box marginX={{ xs: '0px', sm: '30px' }}>
                                <Typography component="a" href={url} target="_blank" marginY={1} fontSize={16}>
                                    {text}
                                </Typography>
                            </Box>
                        </Box>
                    </>
                );
            case 'image':
                return (
                    <>
                        <Box marginY="10px">
                            <Typography variant="h3" color="#616161" marginY={1}>
                                {`${order}. (${type}) ${title}`}
                            </Typography>
                            <Box marginX={{ xs: '0px', sm: '30px' }}>
                                <CardMedia height={256} component="img" src={picture} />
                                <Typography variant="subtitle2" color="gray" marginY={1} fontSize={16}>
                                    {text}
                                </Typography>
                            </Box>
                        </Box>
                    </>
                );
            default:
                return <Typography variant="h4">{langString('datanotavailable')}</Typography>;
        }
    } else {
        return <Typography variant="h4">{langString('datanotavailable')}</Typography>;
    }
}
