import { Grid, Typography } from '@mui/material';
import langString from 'utils/langString';

export default function HeaderDataTable() {
    return (
        <>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <Typography variant="h4" color="initial" noWrap>
                    {`${langString('question')} ${langString('by')} ${langString('students')}`}
                </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                <Typography variant="h4" color="initial">
                    {`${langString('answered')}`}
                </Typography>
            </Grid>
        </>
    );
}
