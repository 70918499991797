import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Card, CardActions, CardContent, Grid, Icon, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import langString from 'utils/langString';
import { rowsDataTable } from './RowsDataTable';

export default function MobileCard({
    id,
    scheduledDate,
    time,
    company,
    industryOfficial,
    action,
    deleteHandle,
    accessDelete
}: rowsDataTable) {
    return (
        <Grid container xs={10} sm={12} justifyContent="center" marginX={{ xs: 'auto', sm: '0px' }} marginY="10px">
            <Card sx={{ border: '1px solid lightgray', borderRadius: '5px', backgroundColor: '#f5f5f5' }}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Typography variant="subtitle1">{`${langString('company')}: ${company}`}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1">{`${langString('industry')}-${langString(
                                'official'
                            )}: ${industryOfficial}`}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} justifyContent="space-between" marginTop="10px">
                        <Grid item>
                            <Typography variant="subtitle2">
                                {`${langString('scheduled')} ${langString('date')}`}: {scheduledDate}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2">
                                {langString('time')}: {time}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid container alignItems="center">
                        <Grid item marginX="auto">
                            <Button component={Link} to="" variant="contained">
                                {langString('views')}
                            </Button>
                        </Grid>
                        <Grid item marginX="auto">
                            {accessDelete && (
                                <Button onClick={() => deleteHandle?.(id?.toString()!)}>
                                    <Icon sx={{ fontSize: ['40px', '42px'], color: 'red' }} component={DeleteIcon} />
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    );
}
