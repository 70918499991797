// material-ui
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Checkbox, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import axiosServices from 'utils/axiosServices';
import { AccessiblePointsT, SystemModulesT, UsersRolesT } from '.';

// styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}));

type CustomizedTablesT = {
    authorityId: string;
    usersRoles: UsersRolesT[];
    systemModules: SystemModulesT[];
    accessiblePoints: AccessiblePointsT[];
    setUsersRoles: React.Dispatch<React.SetStateAction<UsersRolesT[]>>;
    setSystemModules: React.Dispatch<React.SetStateAction<SystemModulesT[]>>;
    setAccessiblePoints: React.Dispatch<React.SetStateAction<AccessiblePointsT[]>>;
};

// ==============================|| TABLE - CUSTOMIZED ||============================== //

export default function CustomizedTables({
    authorityId,
    usersRoles,
    systemModules,
    setUsersRoles,
    accessiblePoints,
    setSystemModules,
    setAccessiblePoints
}: CustomizedTablesT) {
    const [isCheckedAll, setIsCheckAll] = useState(false);
    const [loading, setLoading] = React.useState(false);
    const dispatch = useDispatch();
    const isChecked = (accessiblePointId: any, systemModuleId: any): boolean => {
        const usersRoleIndx = usersRoles.findIndex(
            (usersRole) => `${usersRole.point.id}` === accessiblePointId && `${usersRole.module.id}` === systemModuleId
        );
        return usersRoles[usersRoleIndx]?.access;
    };

    const selectAll = (e: any) => {
        const cloneUserRoles = _.cloneDeep(usersRoles);
        setIsCheckAll(e.target.checked);

        for (let i = 0; i < cloneUserRoles.length; i += 1) {
            cloneUserRoles[i].access = e.target.checked;
        }

        setUsersRoles(cloneUserRoles);
    };
    const selectSystemModuleRow = (e: any, systemModuleId: any) => {
        const cloneUserRoles = _.cloneDeep(usersRoles);
        const cloneSystemModules = _.cloneDeep(systemModules);

        for (let i = 0; i < cloneSystemModules.length; i += 1) {
            if (`${cloneSystemModules[i].id}` === systemModuleId) {
                cloneSystemModules[i].checked = e.target.checked;
            }
        }

        setSystemModules(cloneSystemModules);

        for (let i = 0; i < cloneUserRoles.length; i += 1) {
            if (`${cloneUserRoles[i].module.id}` === systemModuleId) {
                cloneUserRoles[i].access = e.target.checked;
            }
        }

        setUsersRoles(cloneUserRoles);
    };

    const selectSystemModuleColumn = (e: React.ChangeEvent<HTMLInputElement>, accessiblePointId: any) => {
        const cloneUserRoles = _.cloneDeep(usersRoles);
        const cloneAccessiblePoints = _.cloneDeep(accessiblePoints);
        for (let i = 0; i < cloneAccessiblePoints.length; i += 1) {
            if (`${cloneAccessiblePoints[i].id}` === accessiblePointId) {
                cloneAccessiblePoints[i].checked = e.target.checked;
            }
        }
        setAccessiblePoints(cloneAccessiblePoints);

        for (let i = 0; i < cloneUserRoles.length; i += 1) {
            if (`${cloneUserRoles[i].point.id}` === accessiblePointId) {
                cloneUserRoles[i].access = e.target.checked;
            }
        }
        setUsersRoles(cloneUserRoles);
    };

    const handleChange = (e: any, accessiblePointId: any, systemModuleId: any) => {
        const cloneUserRoles = _.cloneDeep(usersRoles);
        const usersRoleIndx = cloneUserRoles.findIndex(
            (usersRole) => `${usersRole.point.id}` === accessiblePointId && `${usersRole.module.id}` === systemModuleId
        );

        cloneUserRoles[usersRoleIndx].access = e.target.checked;
        setUsersRoles(cloneUserRoles);
    };

    const handleClick = async () => {
        setLoading(true);
        try {
            const formattingUserRole = usersRoles.map((userRole) => ({
                id: userRole.id,
                access: userRole.access
            }));
            const usersRolesRes: AxiosResponse<any> = await axiosServices.post(
                `/users/permissions/system-acl/authorities/${authorityId}/update/`,
                {
                    data: {
                        type: 'AuthorityMAP',
                        attributes: {
                            objects: formattingUserRole
                        }
                    }
                }
            );
            if (usersRolesRes.status === 200) {
                const usersRolesMapData = usersRolesRes.data.data.map((usersRole: any) => ({
                    id: usersRole.id,
                    access: usersRole.attributes.access,
                    authority: usersRole.attributes.authority.name,
                    module: { id: usersRole.attributes.map.module.id, name: usersRole.attributes.map.module.name },
                    point: {
                        id: usersRole.attributes.map.point.id,
                        name: usersRole.attributes.map.point.name
                    }
                }));
                setUsersRoles(usersRolesMapData);
                setLoading(false);
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'Successfully saved',
                    variant: 'alert',
                    alertSeverity: 'success',
                    transition: 'Fade'
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <Checkbox checked={isCheckedAll} onChange={(e) => selectAll(e)} />
            Select all
            <MainCard content={false}>
                <TableContainer>
                    <Table sx={{ minWidth: 320 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell sx={{ pl: 5 }} align="left">
                                    #
                                </StyledTableCell>
                                {accessiblePoints.map((accessiblePoint) => (
                                    <StyledTableCell sx={{ pl: 3, textTransform: 'capitalize' }} align="center">
                                        <Checkbox
                                            checked={accessiblePoint.checked}
                                            onChange={(e) => selectSystemModuleColumn(e, accessiblePoint.id)}
                                        />
                                        {accessiblePoint.name.split('_').join(' ')}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {systemModules.map((systemModule) => (
                                <StyledTableRow key={systemModule.id}>
                                    <StyledTableCell sx={{ pl: 3, textTransform: 'capitalize' }} align="left">
                                        <Checkbox
                                            checked={systemModule.checked}
                                            onChange={(e) => selectSystemModuleRow(e, systemModule.id)}
                                        />
                                        {systemModule?.name.split('-').join(' ')}
                                    </StyledTableCell>
                                    {accessiblePoints.map((accessiblePoint) => (
                                        <StyledTableCell
                                            key={accessiblePoint.id}
                                            sx={{ pl: 3, textTransform: 'capitalize' }}
                                            align="center"
                                        >
                                            <Checkbox
                                                checked={!!isChecked(accessiblePoint.id, systemModule.id)}
                                                onChange={(e) => handleChange(e, accessiblePoint.id, systemModule.id)}
                                            />
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </MainCard>
            <Box textAlign="right" marginY={2}>
                <LoadingButton
                    color="primary"
                    onClick={handleClick}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                >
                    Save
                </LoadingButton>
            </Box>
        </>
    );
}
