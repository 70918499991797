// material-ui
import { Autocomplete, Grid, TextField } from '@mui/material';
import { gridSpacing } from 'store/constant';
import langString from 'utils/langString';

// ==============================|| FORM VALIDATION - LOGIN FORMIK  ||============================== //
// autocomplete options
const sequence = [
    { label: `${langString('sequence')}: ${langString('highest')}-${langString('likes')}`, id: 'Highest-Likes' },
    { label: `${langString('sequence')}: ${langString('highest')}-${langString('replies')}`, id: 'Highest-Replies' }
];

const period = [
    { label: `${langString('period')}: ${langString('1')} ${langString('day')}`, id: '1-day' },
    { label: `${langString('period')}: ${langString('1')} ${langString('week')}`, id: '1-week' },
    { label: `${langString('period')}: ${langString('1')} ${langString('month')}`, id: '1-month' },
    { label: `${langString('period')}: ${langString('1')} ${langString('year')}`, id: '1-year' }
];

type filter = {
    selectedPeriod: string;
    selectedSequence: string;
    setPeriod: React.Dispatch<React.SetStateAction<string>>;
    setSequence: React.Dispatch<React.SetStateAction<string>>;
};

const Filter = ({ selectedPeriod, setPeriod, selectedSequence, setSequence }: filter) => (
    <Grid container spacing={gridSpacing}>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Autocomplete
                value={sequence.find((item: any) => item.id === selectedSequence)}
                options={sequence}
                renderInput={(params) => <TextField {...params} label={langString('sequence')} size="small" />}
                onChange={(e, value) => setSequence(value?.id ? value?.id : '')}
            />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Autocomplete
                value={period.find((item: any) => item.id === selectedPeriod)}
                options={period}
                renderInput={(params) => <TextField {...params} label={langString('period')} size="small" />}
                onChange={(e, value) => setPeriod(value?.id ? value?.id : '')}
            />
        </Grid>
    </Grid>
);

export default Filter;
