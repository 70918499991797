import { Box, FormControlLabel, Grid, Typography, Checkbox } from '@mui/material';
import Meta from 'views/contentAndDiscussion/Comment/Meta';
import Counter from 'views/contentAndDiscussion/Comment/Counter';
import { CommentType } from 'views/contentAndDiscussion/Comment';
import { useEffect, useState } from 'react';
import axiosServices from 'utils/axiosServices';
import DataLoading from 'components/common/DataLoading';
import { checkNullInfo } from 'utils/Helpers';
import langString from 'utils/langString';

type CommentsPropsType = {
    forumID: string;
    topicID: string;
    questions: number[];
    updateQuestions: (value: number) => void;
};
export default function QuestionBankComments({ forumID, topicID, questions, updateQuestions }: CommentsPropsType) {
    const [primaryComments, setPrimaryComments] = useState<CommentType[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const init = async () => {
            if (forumID && topicID) {
                // Get Primary comments
                axiosServices
                    .post(`forum-management/forums/${forumID}/topics/${topicID}/comments/`)
                    .then((res) => {
                        if (res.status === 200 && res.data.data) {
                            setPrimaryComments(res.data.data);
                        }
                        setLoading(false);
                    })
                    .catch((e: any) => {
                        console.log(e);
                    });
            }
        };
        init();
    }, [forumID, topicID]);

    return (
        <Box sx={{ marginTop: 3 }}>
            <Typography variant="h4" color="initial" textAlign="center">
                {langString('selectcomments')}
            </Typography>
            {loading && <DataLoading />}
            {primaryComments.length ? (
                primaryComments.map((com: any) => (
                    <Grid container sx={{ marginBottom: 1, background: '#EEE', padding: 3, borderRadius: 5 }}>
                        <Grid item sm={2}>
                            <FormControlLabel
                                control={<Checkbox onChange={() => updateQuestions(com.id)} checked={questions.includes(com.id)} />}
                                label="Select"
                            />
                        </Grid>
                        <Grid item sm={10}>
                            <Grid container>
                                <Grid item sm={10}>
                                    <Meta
                                        author={com.attributes?.created_by?.display_name}
                                        role={com.attributes?.created_by?.user_authority}
                                        createdAt={com.attributes?.created_at}
                                    />
                                    <Typography variant="body1" color="#616161" marginX={1} marginY="5px" fontSize={16}>
                                        {checkNullInfo(com.attributes?.comment)}
                                    </Typography>
                                </Grid>
                                <Grid item sm={2}>
                                    <Box sx={{ marginLeft: 10 }}>
                                        <Counter primary likes={com.attributes?.total_likes} replies={com.attributes?.total_replies} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ))
            ) : (
                <Typography variant="h4" color="initial" textAlign="center">
                    {langString('datanotavailable')}
                </Typography>
            )}
        </Box>
    );
}
