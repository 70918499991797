import { useEffect, useState } from 'react';
import { Button, Container, Grid, Typography, Pagination, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import useAuth from 'hooks/useAuth';
import { checkSystemAcl, fullFilePath } from 'utils/Helpers';
import AccessDenied from 'components/common/AccessDenied';
import DataLoading from 'components/common/DataLoading';
import ReOrder, { ReOrderItemType } from 'components/common/ReOrder';
import { SNACKBAR_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';

export type ForumType = {
    id: number;
    imageUrl: string;
    title: string;
    description: string;
    redirect: string;
    is_deletable?: boolean;
    is_editable?: boolean;
};
export default function ForumList() {
    const [loading, setLoading] = useState(true);
    const [access, setAccess] = useState(false);
    const [forums, setForums] = useState<ForumType[]>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const { user } = useAuth();
    const [reOrders, setReOrders] = useState<ReOrderItemType[]>([]);
    const dispatch = useDispatch();

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const forumDelete = (forumID: number) => {
        if (user && user.acl && checkSystemAcl('forum', ['delete'], user.acl)) {
            setLoading(true);
            axiosServices.delete(`forum-management/forums/${forumID}/delete/`).then((res) => {
                if (res.status === 200) {
                    const allForums: ForumType[] = [...forums];
                    const updatedForums = allForums.filter((fm) => fm.id !== forumID);
                    setForums(updatedForums);
                    setLoading(false);
                }
            });
        }
    };

    const reorderList = (orderedItems: ReOrderItemType[]) => {
        const orderList = orderedItems.map(({ description, ...rest }) => rest);
        const postData = {
            data: {
                type: 'Forum',
                attributes: {
                    objects: orderList
                }
            }
        };
        setLoading(true);
        axiosServices.post(`forum-management/forums/re-order/`, postData).then((res) => {
            if (res.status === 200) {
                const allForums: ForumType[] = [];
                res.data.data.forEach((element: any) =>
                    allForums.push({
                        id: parseInt(element.id, 10),
                        imageUrl: fullFilePath(element.attributes?.picture),
                        title: element.attributes?.name,
                        description: element.attributes?.description,
                        redirect: `/forum-topics/${element.id}`,
                        is_editable: element.is_editable,
                        is_deletable: element.is_deletable
                    })
                );
                setForums(allForums);
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'Forum List Reordered',
                    variant: 'alert',
                    alertSeverity: 'success'
                });
            }
            setLoading(false);
        });
    };

    // server request
    useEffect(() => {
        const init = async () => {
            try {
                if (user && user.acl) {
                    if (checkSystemAcl('forum', ['read'], user.acl)) {
                        setAccess(true);
                        axiosServices.post(`forum-management/forums/?page%5Bnumber%5D=${page}`).then((res) => {
                            if (res.status === 200) {
                                if (res.data?.meta) {
                                    setTotalPages(res.data?.meta?.pagination?.pages);
                                }
                                if (res.data.data) {
                                    const allForums: ForumType[] = [];
                                    res.data.data.forEach((element: any) =>
                                        allForums.push({
                                            id: parseInt(element.id, 10),
                                            imageUrl: fullFilePath(element.attributes?.picture),
                                            title: element.attributes?.name,
                                            description: element.attributes?.description,
                                            redirect: `/forum-topics/${element.id}`,
                                            is_editable: element.is_editable,
                                            is_deletable: element.is_deletable
                                        })
                                    );
                                    setForums(allForums);
                                    setLoading(false);
                                    const orderItemList: ReOrderItemType[] = [];
                                    res.data.data.forEach((item: any) =>
                                        orderItemList.push({
                                            id: item.id,
                                            description: item.attributes.name,
                                            order: item.attributes.order
                                        })
                                    );
                                    setReOrders(orderItemList);
                                }
                            }
                        });
                    }
                }
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };

        init();
    }, [user, page]);

    return (
        <>
            {loading && <DataLoading />}
            {!loading && !access && <AccessDenied />}
            {!loading && access && (
                <Container sx={{ background: '#FFF', padding: 2, borderRadius: 5 }}>
                    <Box>
                        <Grid container xs={12} justifyContent="center">
                            <Grid item xs={12} md={8}>
                                <Grid container xs={12} spacing={3} justifyItems="center" alignItems="center">
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h2" color="initial" textAlign="center" marginY="20px">
                                            {langString('forumlist')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} spacing={3} justifyItems="center" alignItems="center">
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        {user && user.acl && checkSystemAcl('forum', ['create'], user.acl) && (
                                            <Button
                                                component={Link}
                                                to="/add-forum"
                                                variant="contained"
                                                sx={{ marginRight: 2, marginBottom: 1 }}
                                            >
                                                {langString('addnewforum')}
                                            </Button>
                                        )}
                                        {user && user.acl && checkSystemAcl('forum', ['update'], user.acl) && (
                                            <ReOrder
                                                buttonSX={{ marginBottom: 1 }}
                                                items={reOrders}
                                                reorderList={(items: ReOrderItemType[]) => reorderList(items)}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid container marginTop="20px">
                                    <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                        <Typography variant="h4" color="initial" textAlign="center">
                                            {langString('forum')} {langString('title')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                        <Typography variant="h4" color="initial" textAlign="center">
                                            {langString('action')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {forums.length ? (
                                    forums.map((forum: ForumType) => (
                                        <Grid key={forum.id} container sx={{ marginTop: 1 }}>
                                            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                                <Typography
                                                    variant="h4"
                                                    color="initial"
                                                    sx={{ backgroundColor: '#eeeeee', padding: '15px' }}
                                                >
                                                    {forum.title}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5} sx={{ textAlign: 'center' }}>
                                                <Button
                                                    size="small"
                                                    component={Link}
                                                    to={forum.redirect}
                                                    variant="contained"
                                                    sx={{ margin: 1 }}
                                                >
                                                    {langString('view')}
                                                </Button>
                                                {forum.is_deletable && user && user.acl && checkSystemAcl('forum', ['delete'], user.acl) && (
                                                    <Button
                                                        size="small"
                                                        onClick={() => forumDelete(forum.id)}
                                                        variant="contained"
                                                        color="error"
                                                    >
                                                        {langString('delete')}
                                                    </Button>
                                                )}
                                                {forum.is_editable && user && user.acl && checkSystemAcl('forum', ['update'], user.acl) && (
                                                    <Button
                                                        size="small"
                                                        component={Link}
                                                        to={`/edit-forum/${forum.id}/`}
                                                        variant="contained"
                                                        sx={{ margin: 1 }}
                                                        color="success"
                                                    >
                                                        {langString('edit')}
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    ))
                                ) : (
                                    <Typography variant="h4" color="initial" textAlign="center">
                                        {langString('datanotavailable')}
                                    </Typography>
                                )}
                                <Grid container spacing={1} justifyContent="center">
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} textAlign="center">
                                        <Stack spacing={2} sx={{ padding: 1, borderRadius: 5, margin: 2 }}>
                                            <Pagination count={totalPages} page={page} onChange={handleChange} />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            )}
        </>
    );
}
