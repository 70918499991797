import { Container, Grid, Typography } from '@mui/material';
import PrimaryCard from 'components/Card/PrimaryCard';
import DataLoading from 'components/common/DataLoading';
import { useEffect, useState } from 'react';
import axiosServices from 'utils/axiosServices';
import { fullFilePath } from 'utils/Helpers';
import langString from 'utils/langString';
import { ForumType } from 'views/ForumList';
import Dashboard from '../homepage/dashboard';

// type of home page component

export default function GuestPage() {
    const [forums, setForums] = useState<ForumType[]>([]);
    const [loading, setLoading] = useState(false);
    // get all forums
    useEffect(() => {
        setLoading(true);
        // server request
        axiosServices
            .post(`forum-management/forums/`)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data?.data) {
                        const allForums: ForumType[] = [];
                        res.data.data.forEach((element: any) => {
                            allForums.push({
                                id: element.id,
                                imageUrl: fullFilePath(element.attributes?.picture),
                                title: element.attributes?.name,
                                description: element.attributes?.description,
                                redirect: `/guest-forum-topics/${element.id}`
                            });
                        });
                        setForums(allForums);
                        setLoading(false);
                    }
                }
            })
            .catch((e: any) => {
                console.log(e);
            });
    }, []);

    // show all forums
    const showAllForums = forums.map((card) => (
        <PrimaryCard key={card.id} imageUrl={card.imageUrl} title={card.title} description={card.description} redirect={card.redirect} />
    ));

    return (
        <>
            <Container maxWidth="xl">
                <Grid container xs={12} spacing={1} justifyContent="center" sx={{ padding: 5 }}>
                    <Grid item xs={12} sm={12} md={7} lg={7} xl={7} textAlign="center">
                        <Typography variant="h2" color="initial" marginY="15px">
                            {langString('home')} {langString('page')}({langString('forums')})
                        </Typography>
                        <Grid container xs={12} spacing={1}>
                            {showAllForums}
                        </Grid>
                        {loading && <DataLoading />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5} textAlign="center">
                        <Typography variant="h2" color="initial" marginY="15px">
                            {langString('dashboard')}
                        </Typography>
                        <Grid container xs={12} spacing={1}>
                            <Dashboard />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
