import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DatePicker from 'components/common/DatePicker';
import FileInput from 'components/common/FileInput';
import Locations from 'components/common/Locations';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import axiosServices from 'utils/axiosServices';
import langString from 'utils/langString';
import UserInformation from './UserInformation';

export default function SystemUsersCreate() {
    const theme = useTheme();
    // const navigate = useNavigate();
    // const scriptedRef = useScriptRef();
    const dispatch = useDispatch();
    // const { register } = useAuth();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [dob, setDob] = useState('');
    const [address, setAddress] = useState(0);
    const [gender, setGender] = useState('male');
    const [userAuthority, setUserAuthority] = useState('system-admin');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [picture, setPicture] = useState<any>(null);
    const [usernameExist, setUsernameExist] = useState(false);
    const [allFilled, setAllFilled] = useState(false);

    const studentSignUp = async () => {
        if (!usernameExist && username && password && name && email && phone && address && userAuthority) {
            setAllFilled(true);
            try {
                const user = {
                    username,
                    password,
                    user_authority: userAuthority,
                    profile: {
                        name,
                        email,
                        phone,
                        dob,
                        address: {
                            id: address,
                            type: 'GeoLocation'
                        },
                        gender,
                        picture
                    }
                };
                const response = await axiosServices.post('users/system-users/create/', { data: { type: 'User', attributes: user } });
                if (response.status === 201) {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: 'Your registration has been successfully completed.',
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                }

                // await register();
                // if (scriptedRef.current) {
                //     dispatch({
                //         type: SNACKBAR_OPEN,
                //         open: true,
                //         message: 'Your registration has been successfully completed.',
                //         variant: 'alert',
                //         alertSeverity: 'success'
                //     });
                //     setTimeout(() => {
                //         navigate('/login', { replace: true });
                //     }, 1500);
                // }
            } catch (err: any) {
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: 'Registration not successfully.Try again.',
                    variant: 'alert',
                    alertSeverity: 'error'
                });
            }
        } else {
            setAllFilled(false);
        }
    };

    return (
        <>
            <Container maxWidth="lg">
                <Grid container direction="column" justifyContent="center" spacing={2}>
                    <Grid item xs={12} container alignItems="center" justifyContent="center">
                        <Box sx={{ mb: 2 }}>
                            <Typography variant="subtitle1">{langString('personaldetails')}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={matchDownSM ? 0 : 2}>
                    <Grid item xs={12} sm={6}>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                    <TextField
                                        fullWidth
                                        label={`${langString('name')} *`}
                                        margin="normal"
                                        name="name"
                                        type="text"
                                        value={name}
                                        onChange={(event: any) => setName(event.target.value)}
                                        sx={{ ...theme.typography.customInput }}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                    <FileInput fileType="image" setFile={(value: any) => setPicture(value)} />
                                    <InputLabel sx={{ marginTop: 2 }} htmlFor="picture">
                                        Upload Profile Photo
                                    </InputLabel>
                                </FormControl>
                                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                    <TextField
                                        fullWidth
                                        label={`${langString('email')} *`}
                                        margin="normal"
                                        name="email"
                                        type="email"
                                        value={email}
                                        onChange={(event: any) => setEmail(event.target.value)}
                                        sx={{ ...theme.typography.customInput }}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                    <TextField
                                        fullWidth
                                        label={`${langString('phone')} *`}
                                        margin="normal"
                                        name="phone"
                                        type="text"
                                        value={phone}
                                        onChange={(event: any) => setPhone(event.target.value)}
                                        sx={{ ...theme.typography.customInput }}
                                    />
                                </FormControl>
                                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                    <InputLabel id="userAuthority-select-label">User Authority</InputLabel>
                                    <Select
                                        labelId="userAuthority-select-label"
                                        id="userAuthority-select"
                                        value={userAuthority}
                                        label="User Authority"
                                        onChange={(event: any) => setUserAuthority(event.target.value)}
                                    >
                                        <MenuItem value="system-admin">System Admin</MenuItem>
                                        <MenuItem value="moderator">Moderator</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                                    <InputLabel id="gender-select-label">Gender</InputLabel>
                                    <Select
                                        labelId="gender-select-label"
                                        id="gender-select"
                                        value={gender}
                                        label="Gender"
                                        onChange={(event: any) => setGender(event.target.value)}
                                    >
                                        <MenuItem value="male">Male</MenuItem>
                                        <MenuItem value="female">Female</MenuItem>
                                        <MenuItem value="other">Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Typography sx={{ marginBottom: 1 }} variant="h6">
                                    Birth Date
                                </Typography>
                                <DatePicker setYearMonthDay={(value: string) => setDob(value)} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {picture && (
                            <div style={{ padding: 10, border: '1px solid #EEE', textAlign: 'center' }}>
                                <img src={picture} alt="profile" width="100" />
                            </div>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={{ marginTop: 2 }}>
                    <Grid item xs={12} sm={12}>
                        <Typography sx={{ marginBottom: 1 }} variant="h6">
                            {langString('address')}*
                        </Typography>
                        <Locations address={address} setAddress={(value: number) => setAddress(value)} />
                    </Grid>
                </Grid>
                <UserInformation
                    username={username}
                    setUsername={(value: string) => setUsername(value)}
                    password={password}
                    setPassword={(value: string) => setPassword(value)}
                    usernameExist={usernameExist}
                    setUsernameExist={(value: boolean) => setUsernameExist(value)}
                />
                <Grid container sx={{ marginTop: 2 }}>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <Button disabled={usernameExist} variant="contained" onClick={() => studentSignUp()}>
                                {langString('signup')}
                            </Button>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {!allFilled && (
                            <Typography variant="h5" color="#d35400" textAlign="center">
                                {langString('allsignedfieldsrequired')}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
